import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import { BreakEvent, CommandEvent } from '@/frame/Event.js';
import Pick from '@/visual-events/view/Pick';
import GridProvider from './GridProvider';
import { GridMode } from './GridProvider';
import PlaceSymbolsList from './PlaceSymbolsList';
import Settings from '../data/Settings';
import { RenumberTool } from './RenumberTool';
import FltSelectAndDispatch from '@/visual-events/actions/FltSelectAndDispatch';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Geometry from '@/visual-events/model/Geometry';
import BlockUtils from './BlockUtils';
import OpReference from '@/visual-events/model/OpReference';
import OpUtils from '@/visual-events/model/OpUtils';
import Event3DPlacing from '@/visual-events/model/Event3DPlacing';
import SelectGrafic from '@/visual-events/actions/SelectGrafic';
import { RectangleSelection } from '@/visual-events/actions/FltPick';
import Synchronizer3D from '@/visual-events/actions/Synchronizer3D';
import TableShapeUtils from '@/visual-events/model/TableShapeUtils';
import CommandLineScanner from '@/frame/CommandLineScanner';
import Variant from '@/visual-events/model/Variant';
import Logger from '@/frame/Logger';
import { nextTick } from 'vue';
import { Matrix4, Vector3 } from 'three';
import VariantReplace from '@/visual-events/actions/VariantReplace';
import { CommandDispatcher } from './FltSelectAndDispatch';

const State = Object.freeze({
  SELECT: 0,
  EDIT_BLOCK: 1,
  DEFINE_GAPS: 7
});

const logger = new Logger('FltPlaceBlock');

export default class FltPlaceBlock extends Action {
    constructor(args) {
      super();

      this.state = State.SELECT;

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.view3D = theApp.findViewByName('3D Ansicht'); 
      this.root3D = this.view3D?.getRoot();

      this.block = '';
      this.category = '';

      // calculation of grid positions and angles
      this.grid = new GridProvider();
      
      this.grid.cntX = Settings.get('grid.cntX');
      this.grid.cntY = Settings.get('grid.cntY');
      this.grid.widthX = 0.0;
      this.grid.widthY = 0.0;
      this.grid.distX = Settings.get('grid.distX');
      this.grid.distY = Settings.get('grid.distY');

      // the temporary list of OpReferences in the block
      this.placeSymbolsList = new PlaceSymbolsList(this.root2D);

      // seat and row numbers
      this.renumberTool = new RenumberTool();
      this.renumberTool.modeInRow = Settings.get('numeration.modeInRow');
      this.renumberTool.modeAlternate = Settings.get('numeration.modeAlternate');
      this.renumberTool.modeRows = Settings.get('numeration.modeRows');
      this.renumberTool.startNumber = Settings.get('numeration.startNumber');
      this.renumberTool.displaySeatNo = Settings.get('numeration.displaySeatNo') || Settings.get('numeration.displaySeatNoAtTable');

      this.renumberTool.tableStartNumber = Settings.get('numeration.tableStartNumber'),
      this.renumberTool.tableModeInRow = Settings.get('numeration.tableModeInRow'),
      this.renumberTool.tableModeAlternate = Settings.get('numeration.tableModeAlternate'),
      this.renumberTool.tableModeRows = Settings.get('numeration.tableModeRows'),
      this.renumberTool.displayTableNo = Settings.get('numeration.displayTableNo');

      this.renumberTool.rowStartNumber = Settings.get('numeration.rowStartNumber');
      this.renumberTool.rowModeDirection = Settings.get('numeration.rowModeDirection');
      this.renumberTool.rowModeText = Settings.get('numeration.rowModeText');
      this.renumberTool.rowTextSize = Settings.get('numeration.rowTextSize');
      this.renumberTool.rowTextDist = Settings.get('numeration.rowTextDist');
      this.renumberTool.displayRowNo = Settings.get('numeration.displayRowNo');

      this.renumberTool.seatsAtTableMode = Settings.get('numeration.seatsAtTableMode');
      this.renumberTool.seatsAtTableStartNumber = Settings.get('numeration.seatsAtTableStartNumber');
      this.renumberTool.displaySeatNoAtTable = Settings.get('numeration.displaySeatNoAtTable') || Settings.get('numeration.displaySeatNo');

      // the variantType determines, which sub dialogs are feasible for renumbering and for furniture exchange
      this.variant = undefined;
      this.allowTriangleMode = false; 

      // 2D 3D coupling
      this.synchronizer3D = new Synchronizer3D();
      this.block3D = null;

      this.grafic = null; // temporary grafic

      // selection grafic only for DEFINE_GAPS
      this.selectGrafic = null;

      // working matrix
      this.t = new Matrix4();
      this.v = new Vector3();

      this.objects = [];
      if (args.length > 1)
        this.objects = args[1];
    }

    actionStart () {
        logger.log('actionStart');

        this.state = State.SELECT;
        if (this.objects.length > 0) {
            const op = this.objects[0];

            const block = BlockUtils.findBlockGroup(op);
            if (block) {
                this.variant = this.determineVariant(op);
                this.editGrid(block);
                this.state = State.EDIT_BLOCK;
            } else if (op instanceof OpReference) {
                this.variant = this.determineVariant(op);
                this.adaptDistX();
                op.removeFromParent();
                this.createGrid(op);
                this.addToModel();
                this.state = State.EDIT_BLOCK;
            }

            if (this.state === State.EDIT_BLOCK)
                this.addFltSelectGrafic();
            else 
                this.addFilter(new FltSelectAndDispatch());

        }   

        this.connectToGUI();
        return true;
    }

    actionDestroy () {
        logger.log('actionDestroy');
        switch (this.state) {
            case State.SELECT:
                break;
            case State.EDIT_BLOCK:
                this.finishGrid();
                break;
            case State.DEFINE_GAPS:
                this.finishGrid();
                this.removeSelectGrafic();
                break;
        }

        this.view2D.enableCameraControls();

        this.disconnectFromGUI();
    }

    actionBreak (event) {
        logger.log('actionBreak');
        switch (this.state) {
            case State.SELECT:
                break;
            case State.EDIT_BLOCK:
                this.clearGrid();
                break;
            case State.DEFINE_GAPS:
                // proceed with editing
                this.removeSelectGrafic();
                this.addFltSelectGrafic();
                this.updateModeButtons();
                this.state = State.EDIT_BLOCK;
                return null;
        }

        this.view2D.enableCameraControls();

        return event;
    }

    actionPoint (event) {
        logger.log(`actionPoint  ${this.state}`);

        if (event.view !== this.view2D)
            return;

        switch (this.state) {
            case State.SELECT:
            case State.EDIT_BLOCK:
                if (event.view === this.view2D) {
                    const hits = Pick.pick(event.view, event.raw, ['XOpSymbol']);
                    let op = hits[0];
                    if (op) {
                        if (this. placeSymbolsList.contains(op)) {
                            logger.log(`${op} in active block`);
                            // nothing to do
                        } else {
                            logger.log(`${op} not in active block`);

                            this.variant = this.determineVariant(op);
                            this.updateDialogs();

                            const block = BlockUtils.findBlockGroup(op);

                            if (block) {
                                logger.log(`picked other block`);

                                // disconnect the current block from the GridProvider
                                this.finishGrid();

                                // prepare the grid for 'block's parameters and start drag&drop
                                this.editGrid(block);

                                this.addFltSelectGrafic();
                                this.state = State.EDIT_BLOCK;
                            } else if (op instanceof OpReference) {
                                logger.log(`picked isolated OpReference`);

                                // disconnect the current block from the GridProvider
                                this.finishGrid();

                                // prepare the grid for 'block's parameters and start drag&drop
                                op.removeFromParent();
                                this.createGrid(op);
                                this.addToModel();
                 
                                this.state = State.EDIT_BLOCK;
                            } else {
                                logger.log(`picked but no block nor OpReference`);
                                this.state = State.SELECT;
                            }
                        }
                    } else {
                        logger.log(`picked in empty space`);
                        // leave the action
                        return new BreakEvent();                    
                    }

                    if (this.state === State.EDIT_BLOCK)
                        this.addFltSelectGrafic();
                    else 
                        this.addFilter(new FltSelectAndDispatch());
                }
                break;
                case State.DEFINE_GAPS:
                    // handled in FltDefineGaps
                break;
            }

        return null;
    }

    actionSelection (event) {
        logger.log(`actionSelection`);

        switch (this.state) {
            case State.SELECT:
            case State.EDIT_BLOCK:
                // does not happen
                break;
            case State.DEFINE_GAPS: {
                // handles in FltDefineGaps
                break;
            }
        }
      
        return null;
    }

    actionCommand (event) {
        const scanner = new CommandLineScanner(event.commandLine);

        const cmd = scanner.getCommand();
        switch (cmd) {
            // from SelectGrafic
            case '.select.delete': {
                this.removeFromModel();
                this.clearGrid();
                return new BreakEvent();
            }
            case '.select.copy': {
                this.copySelection();
                return null;
            }
            case '.select.applyTransform': {

                const tdiff = event.args[0];

                this.v.set(this.grid.origin.x, this.grid.origin.y, 0);
                this.v.applyMatrix4(tdiff);
                this.grid.origin.x = this.v.x;
                this.grid.origin.y = this.v.y;

                const rotate = Geometry.getRotationAngle(tdiff);
                this.grid.setAngleX(this.grid.angleX + rotate);
                this.grid.setAngleY(this.grid.angleY + rotate);

                this.updateGrid();
                return null;
            }
            case '.select.dragBoxPoint': {
                const box = event.args[1];
                const dragDirection = event.args[0];
                
                const boxHeight = Math.abs(box.max.y - box.min.y);
                const boxWidth  = Math.abs(box.max.x - box.min.x);
                
                const diffWidth = boxWidth - this.grid.getLengthX();
                const diffCntX = Math.floor(diffWidth / this.grid.getOffsetX());
                const diffHeight = boxHeight - this.grid.getLengthY();
                const diffCntY = Math.floor(diffHeight / this.grid.getOffsetY());

                if(diffCntX !=0 && this.grid.cntX+diffCntX > 0){
                    if(dragDirection.includes('left')){
                        this.grid.origin.x = this.grid.origin.x -  diffCntX * this.grid.getOffsetX() * this.grid.vecX.x;
                        this.grid.origin.y = this.grid.origin.y -  diffCntX * this.grid.getOffsetX() * this.grid.vecX.y;
                    }
                        

                    this.grid.cntX = this.grid.cntX + diffCntX;
                    const data = this.rememberGaps(this.placeSymbolsList.block);
                    this.updateGrid();
                    this.applyGaps(data);
                }

                if(diffCntY !=0 && this.grid.cntY+diffCntY > 0){
                    if(dragDirection.includes('bottom') || dragDirection.includes('Bottom')){
                        this.grid.origin.y = this.grid.origin.y - diffCntY * this.grid.getOffsetY() * this.grid.vecY.y;
                        this.grid.origin.x = this.grid.origin.x - diffCntY * this.grid.getOffsetY() * this.grid.vecY.x;
                    }
                        

                    this.grid.cntY = this.grid.cntY + diffCntY;
                    this.updateGrid();
                }

                this.updateFltSelectGrafic();
                return null;
            }
            case '.FltPlaceBlock.defineGaps': {
                this.addSelectGrafic();
                this.addFilter(new FltSelectAndDispatch().first(new FltDefineGaps(this.placeSymbolsList.block.id, this.selectGrafic)));
                this.state = State.DEFINE_GAPS;
                return null; // do not propagate internal command
            }
            case '.FltPlaceBlock.fillGaps': {
                const block = this.placeSymbolsList.block;
                for(const child of block.children) {
                    for( const op of child.children) {
                        op.setVisibility(true);
                        theApp.model.changed2d = true;
                    }
                }
                this.removeSelectGrafic();
                this.addFltSelectGrafic();
                this.updateModeButtons();
                this.state = State.EDIT_BLOCK;
                return null; // do not propagate internal command
            }
            case '.FltPlaceBlock.remainInGapState': {
                // HACK: this dummy command is necessary for communication according to the current CommandDispatcher protocol
                // otherwise the dispatcher would proceed with looking for a command
                return null; // do not propagate internal command
            }
        }

        return event;
    }    

    actionValue (event) {
        logger.log(`actionValue ${event.attribute} ${event.value}`);
        if (event.attribute === 'mode') {
            this.grid.mode = event.value;
            //this.adaptDistX();
            if (this.state === State.DEFINE_GAPS) {
                this.removeSelectGrafic();
                this.addFltSelectGrafic();
                this.state = State.EDIT_BLOCK;
            }
            this.updateGrid();
            this.addFltSelectGrafic();
        }

        if (event.attribute === 'block') {
            this.block = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'category') {
            this.category = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'cntX') {
            this.grid.cntX = event.value;
            const data = this.rememberGaps(this.placeSymbolsList.block)
            this.updateGrid();
            this.applyGaps(data)
        }

        if (event.attribute === 'cntY') {
            this.grid.cntY = event.value;
            this.updateGrid();
        }
        
        if (event.attribute === 'distX') {
            this.grid.distX = event.value;
            this.updateGrid();
        }
        
        if (event.attribute === 'distY') {
            this.grid.distY = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'startNumber') {
            this.renumberTool.startNumber = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'modeInRow') {
            this.renumberTool.modeInRow = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'modeAlternate') {
            this.renumberTool.modeAlternate = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'displaySeatNo') {
            this.renumberTool.displaySeatNo = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'tableStartNumber') {
            this.renumberTool.tableStartNumber = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'tableModeInRow') {
            this.renumberTool.tableModeInRow = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'tableModeAlternate') {
            this.renumberTool.tableModeAlternate = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'tableModeRows') {
            this.renumberTool.tableModeRows = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'displayTableNo') {
            this.renumberTool.displayTableNo = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'modeRows') {
            this.renumberTool.modeRows = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'rowStartNumber') {
            this.renumberTool.rowStartNumber = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'rowModeDirection') {
            this.renumberTool.rowModeDirection= event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'rowModeText') {
            this.renumberTool.rowModeText= event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'rowTextSize') {
            this.renumberTool.rowTextSize= event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'rowTextDist') {
            this.renumberTool.rowTextDist= event.value;
            this.updateGrid();
        }

        if (event.attribute === 'displayRowNo') {
            this.renumberTool.displayRowNo = event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'seatsAtTableMode') {
            this.renumberTool.seatsAtTableMode= event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'seatsAtTableStartNumber') {
            this.renumberTool.seatsAtTableStartNumber= event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'displaySeatNoAtTable') {
            this.renumberTool.displaySeatNoAtTable= event.value;
            this.updateGrid(true);
        }

        if (event.attribute === 'angleX') {
            this.setAngle(event.value);
            this.updateGrid();
        }

        if (event.attribute === 'angleY') {
            //TODO: implement slanted grids correctly, keep row distance, ( maybe set maximum, e.g. 45°?) 
            //TODO: Value events mit value nicht Texte sondern im 'richtigen' Typ, z.B. hier Integer, bei alternate boolean true
            this.grid.setAngleY(event.value);
            this.updateGrid();
        }

        if (event.attribute === 'alternateMode') {
            this.grid.alternateMode = event.value;
            this.updateGrid();
        }

        if (event.attribute === 'keepRatioXY') {
            this.grid.keepRatioXY = event.value;
            this.grid.distX = this.grid.keepRatioXY || this.isRound() ? Settings.get('grid.triangleDistX') : Settings.get('grid.distX');
            const panelPlaceBlock = theApp.findDialogByName('PanelPlaceBlock');
            panelPlaceBlock?.update(this);
            this.updateGrid();
        }

        // 
        // from furniture dialogs
        // 
        switch (event.attribute) {
            case 'chairShape':
            case 'chairColor':
            case 'chairDiameter':
            case 'chairWidth':
            case 'chairHeight':
            case 'tableColor':
            case 'tableDiameter':
            case 'tableWidth':
            case 'tableHeight':
            case 'numberOfChair':
            case 'freeSpaces':
            case 'chairTop':
            case 'chairBottom':
            case 'chairLeft':
            case 'chairRight': {
                this.editVariantProperty(event);
                break;
            }
        }

        if (this.state === State.DEFINE_GAPS)
            this.updateSelectGrafic();
        else 
            this.updateFltSelectGrafic();
    }

    /**
     * apply the gaps only on items in the current block
     * @param {*} objects 
     */
    applyGapsOnCurrentBlockGroup(objects) {
        for (const op of objects) {
            if(this.isInCurrentBlock(op)) {
                op.setVisibility(false);
                theApp.model.changed2d = true;
            }
        }
    }

    isInCurrentBlock(op) {
        const block = BlockUtils.findBlockGroup(op);
        return this.placeSymbolsList.block.id === block.id;
    }

    /**
     * remember the current block gap positions
     * @param {*} block 
     * @returns 
     */
    rememberGaps(block) {
        const gaps = [];
        for (let i = 0; i < block.children.length; i++) {
            const gap = [];
            const row = block.children[i];
            for (let j = 0; j < row.children.length; j++) {
                const column = row.children[j];
                if(column.visible != true) {
                    gap.push(j);
                }
            }
            gaps.push(gap);
        }
        return gaps;
    }

    /**
     * while row and column are changed keep the previous gap positions
     * @param {*} gaps
     */
    applyGaps(gaps) {
        const block = this.placeSymbolsList.block;
        for (let i = 0; i < block.children.length; i++) {
            const row = block.children[i];
            for (let j = 0; j < row.children.length; j++) {
                const op = row.children[j];
                op.setVisibility(true);

                if(gaps[i].length > 0) {

                    for (let k = 0; k < gaps[i].length; k++) {
                        let position = gaps[i][k];

                        if(((position - row.children.length) === 0) && row.children.length > 1) {
                            position = position - 1;
                        }

                        if(position === j) {
                            op.setVisibility(false);
                        }
                    }
                }

                theApp.model.changed2d = true;
            }
        }
    }

    /**
     * triangle mode is only allowed for round tables
     * @param {*} op 
     */
    adaptTriangleMode (op) {
        this.allowTriangleMode = TableShapeUtils.isSymbolTableCircle(op)
                              || this.isInventoryRoundTable(op);
        if (!this.allowTriangleMode && this.grid.alternate && this.grid.keepRatioXY) {
            this.grid.alternate = false; // ! switch back to the simpliest mode
            this.grid.keepRatioXY = false;
        }
    }

    isInventoryRoundTable (op) {
        const variant = this.determineVariant (op);

        return variant?.type === 'INVENTORY' && variant.allowTriangleMode;
    }

    determineVariant (op) {
        if (!(op instanceof OpReference))
            return false;

        const symbolId  = op.symbolId;
        const symbol = theApp.model.symbols.get(symbolId);
        const variant = Variant.getVariant(symbol);
        return variant;
    }

    /**
     * modify grid angles and origin in order to achieve a rotation around the center
     * @param {*} angle 
     */

    setAngle (angle) {
        // calculate the center of the current selectBox
        const box = BlockUtils.computeSelectBox(this.placeSymbolsList.prototype, this.grid);
        BlockUtils.makeGridTransform(this.t, this.grid);
        Geometry.makeBoxCenter(this.v, box, this.t);
        // rotate the grid origin around center by the difference angle
        Geometry.makeRotationZ(this.t, this.v,  angle - this.grid.angleX);
        this.v.set(this.grid.origin.x, this.grid.origin.y, 0);
        this.v.applyMatrix4(this.t);

        this.grid.origin.x = this.v.x;
        this.grid.origin.y = this.v.y;
        this.grid.setAngleX(angle);
        this.grid.setAngleY((angle*1 + 90.0) % 360.0);
    }

    /**
     * delete the current block
     */
    clearGrid() {
        this.placeSymbolsList.resetSymbolList();
        theApp.model.changed2d = true;
    }

    /**
     * finish the current block 
     */
    finishGrid() {
        BlockUtils.storeBlockParameters(this.placeSymbolsList.block, this.grid, this.renumberTool);
        this.placeSymbolsList.resetSymbolList();
    }

    createGrid(op) {

        BlockUtils.analyseSymbol(op, this.grid);

        this.adaptTriangleMode(op);

        this.placeSymbolsList.prototype = op;
        const box = op.computeBox();
        this.renumberTool.box = box;

        this.placeSymbolsList.adaptSymbolList(this.grid);
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);

        BlockUtils.storeBlockParameters(this.placeSymbolsList.block, this.grid, this.renumberTool);

        // 2D-3D
        const block2D = this.placeSymbolsList.block;
        this.block3D = BlockUtils.createBlockGroup();
        const map = {};
        this.synchronizer3D.fillMap(op, map); //take the mapping from the prototype
        const link = Event3DPlacing.findLink2DTo3D(op)
        if (link) {
            link.op3D.removeFromParent();
            Event3DPlacing.unlink2DFrom3D(op);
        }
        this.synchronizer3D.apply(block2D, this.block3D, map);
        theApp.model.changed3d = true;
    }

    editGrid(block) {

        BlockUtils.analyseBlockGroup(block, this.grid);
        BlockUtils.retrieveBlockParameters(block, this.grid, this.renumberTool);
        BlockUtils.fillPlaceSymbolList(block, this.placeSymbolsList)

        this.adaptTriangleMode(this.placeSymbolsList.prototype);

        this.renumberTool.box = this.placeSymbolsList.prototype.computeBox();

        this.placeSymbolsList.adaptSymbolList(this.grid);
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);

        theApp.model.changed2d = true;

        BlockUtils.storeBlockParameters(this.placeSymbolsList.block, this.grid, this.renumberTool);

        // 2D-3D
        const block2D = this.placeSymbolsList.block;
        this.block3D = this.synchronizer3D.find3DBlock(block2D);
        if (this.block3D) {
            const map = {};
            this.synchronizer3D.fillMap(block2D, map);
            this.synchronizer3D.apply(block2D, this.block3D, map);
            theApp.model.changed3d = true;
        }

        this.updateDialogs();
    }

    updateGrid(enforceRerender = false) {
        const changedSymbolList = this.placeSymbolsList.adaptSymbolList(this.grid);
        this.placeSymbolsList.adaptSymbolPositions(this.grid);
        this.placeSymbolsList.adaptSymbolNumbering(this.grid, this.renumberTool);

        // if there are only moved existing OpObjects around it is sufficient to
        // rerender only the items in the block and the temporary grafic
        if (changedSymbolList || enforceRerender)
            theApp.model.changed2d = true;
        else {
            theApp.model.setModifiedIn2D(this.placeSymbolsList.block);
        }

        BlockUtils.storeBlockParameters(this.placeSymbolsList.block, this.grid, this.renumberTool);

        // 2D-3D
        if (changedSymbolList){
            const block2D = this.placeSymbolsList.block;
            this.block3D = this.synchronizer3D.find3DBlock(block2D);
            if (this.block3D) {
                const map = {};
                this.synchronizer3D.fillMap(block2D, map);
                this.synchronizer3D.apply(block2D, this.block3D, map);
                theApp.model.changed3d = true;
            }
        }

        this.updateDialogs();
    }

    addToModel() {
        this.placeSymbolsList.addToModel();

        if (this.root3D && this.block3D) {
            this.root3D.add(this.block3D);
            theApp.model.changed3d = true;
        }
    }

    removeFromModel() {
        this.placeSymbolsList.removeFromModel();

        if (this.block3D) {
            this.block3D.removeFromParent();
            theApp.model.changed3d = true;
        }
    }

    copySelection () {
        const shift = Settings.get('selection.grafic.iconDist', 300);

        // copy the current block
        const block2D = this.placeSymbolsList.block;
        const objects = OpUtils.copySelection ([block2D], this.root2D, shift);
        const copiedBlock2D = BlockUtils.findBlockGroup(objects[0]);

        // use the map of the original block to generate the 3D block for the copied block
        const block3D = this.synchronizer3D.find3DBlock(block2D);
        if (block3D) {
            const map = {};
            this.synchronizer3D.fillMap(block2D, map);

            const copiedBlock3D = block3D.copy();
            copiedBlock3D.attributes = {...block3D.attributes};
            this.root3D.add(copiedBlock3D);

            this.synchronizer3D.apply(copiedBlock2D, copiedBlock3D, map);
            theApp.model.changed3d = true;

            this.block3D = copiedBlock3D;
        }

        // disconnect the current block from the GridProvider
        this.finishGrid();

        // bind the copied block with the GridProvider, the PlaceSymbolsList and the RenumberTool
        BlockUtils.analyseBlockGroup(copiedBlock2D, this.grid);
        BlockUtils.retrieveBlockParameters(copiedBlock2D, this.grid, this.renumberTool);
        BlockUtils.fillPlaceSymbolList(copiedBlock2D, this.placeSymbolsList)
        this.renumberTool.box = this.placeSymbolsList.prototype.computeBox();

        //it is sufficient to just shift the selection grafic
        // also avoids premature BreakEvent due to unpaired PointUp (HACK in SelectGrafic)
        this.getFilter().shift(shift);
        theApp.model.changed2d = true;
        
        this.state = State.EDIT_BLOCK;
    }

    /**
     * while editing FltSelectGrafic does most of the job
     */
    addFltSelectGrafic () {
        const box = BlockUtils.computeSelectBox(this.placeSymbolsList.prototype, this.grid);
        Geometry.makePlacingTransform(this.t, this.grid.origin.x, this.grid.origin.y, 0, this.grid.angleX);
        if(this.grid.mode === GridMode.SINGLE){
            this.addFilter(new FltSelectGrafic(box, this.t).useDeleteIcon().useCopyIcon().useRotateIcon());
        }else{
            this.addFilter(new FltSelectGrafic(box, this.t).useDeleteIcon().useCopyIcon().useRotateIcon().useBoxPoints());
        }
        
    }

    updateFltSelectGrafic () {
        const box = BlockUtils.computeSelectBox(this.placeSymbolsList.prototype, this.grid);
        Geometry.makePlacingTransform(this.t, this.grid.origin.x, this.grid.origin.y, 0, this.grid.angleX);
        this.getFilter().update(box, this.t);
    }

    addSelectGrafic () {
        this.selectGrafic = new SelectGrafic();
        const box = BlockUtils.computeSelectBox(this.placeSymbolsList.prototype, this.grid);
        Geometry.makePlacingTransform(this.t, this.grid.origin.x, this.grid.origin.y, 0, this.grid.angleX);
        this.root2D.add(this.selectGrafic.create(box, this.t));
        theApp.model.changed2d = true; //op;
    }

    updateSelectGrafic () {
        const box = BlockUtils.computeSelectBox(this.placeSymbolsList.prototype, this.grid);
        Geometry.makePlacingTransform(this.t, this.grid.origin.x, this.grid.origin.y, 0, this.grid.angleX);
        this.selectGrafic.adapt(box, this.t);
        theApp.model.changed2d = true; //op;
    }

    removeSelectGrafic () {
        this.selectGrafic?.dispose();
        this.selectGrafic = null;
        theApp.model.changed2d = true; //op;
    }

    updateModeButtons () {
        const panelPlaceBlock = theApp.findDialogByName('PanelPlaceBlock');
        panelPlaceBlock?.setMode('UNDEFINED') // first reset mode; only so you can enforce next change
        nextTick(() => panelPlaceBlock?.setMode(this.grid.mode))
    }

    updateDialogs () {
        const dlgPlaceBlock = theApp.findDialogByName('DlgPlaceBlock');
        const panelPlaceBlock = theApp.findDialogByName('PanelPlaceBlock');
        panelPlaceBlock?.update(this);
        dlgPlaceBlock?.setVariantType(this.variant.type);
        const panelRenumberRows = theApp.findDialogByName('PanelRenumberRows');
        panelRenumberRows?.update(this);
        const panelRenumberSeats = theApp.findDialogByName('PanelRenumberSeats');
        panelRenumberSeats?.update(this);
        const panelRenumberSeatsForTable = theApp.findDialogByName('PanelRenumberSeatsForTable');
        panelRenumberSeatsForTable?.update(this);
        const panelRenumberTables = theApp.findDialogByName('PanelRenumberTables');
        panelRenumberTables?.update(this);
        const panelAllChair = theApp.findDialogByName('PanelAllChair');
        panelAllChair?.update(this);
        const panelTableCircle = theApp.findDialogByName('PanelTableCircle');
        panelTableCircle?.update(this);
        const panelTableRectangle = theApp.findDialogByName('PanelTableRectangle');
        panelTableRectangle?.update(this);
        const panelTableCircleSetting = theApp.findDialogByName('PanelTableCircleSetting');
        panelTableCircleSetting?.update(this);
        const panelTableRectangleSetting = theApp.findDialogByName('PanelTableRectangleSetting');
        panelTableRectangleSetting?.update(this);
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Seating');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('DlgPlaceBlock');
        //TODO: nextTick ... update (nach dem Füllen des grid!)
        nextTick(() => this.updateDialogs());
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }

    isRound() {
        return this.variant.type === 'TABLE_CIRCLE_WITH_SEATS' || 
               (this.variant.type === 'INVENTORY' && this.variant.tags['form'] && this.variant.tags['form'].includes('rund'));
    }

    adaptDistX() {
        if (this.isRound()) {
            this.grid.distX = Settings.get('grid.triangleDistX');
            // Settings.set('grid.distX', this.grid.distX);
        }
    }

    // interface communication with the furniture vuejs panel components
    get chairShape() {
        switch(this.variant.type) {
            case 'CHAIR_CIRCLE': 
                return 'SHAPE_CIRCLE';
            case 'CHAIR_RECTANGLE': 
                return 'SHAPE_RECTANGLE';
            case 'CHAIR_BANQUET':
                return 'SHAPE_BANQUET';
            case 'TABLE_CIRCLE_WITH_SEATS':
            case 'TABLE_RECTANGLE_WITH_SEATS': 
                return this.variant.opts.chairShape; 
            default:
                return undefined;
        }
    }

    get chairColor() {
        switch(this.variant.type) {
            case 'CHAIR_CIRCLE': 
            case 'CHAIR_RECTANGLE': 
            case 'CHAIR_BANQUET':
                return this.variant.opts.color;
            case 'TABLE_CIRCLE_WITH_SEATS':
            case 'TABLE_RECTANGLE_WITH_SEATS': 
                return this.variant.opts.chairColor; 
            default:
                return undefined;
        }
    }

    get chairDiameter() {
        switch(this.variant.type) {
            case 'CHAIR_CIRCLE': 
                return this.variant.opts.diameter;
            case 'TABLE_CIRCLE_WITH_SEATS':
            case 'TABLE_RECTANGLE_WITH_SEATS': 
                return this.variant.opts.chairDiameter; 
            default:
                return undefined;
        }
    }
    
    get chairWidth() {
        switch(this.variant.type) {
            case 'CHAIR_RECTANGLE': 
            case 'CHAIR_BANQUET':
                return this.variant.opts.width;
            case 'TABLE_CIRCLE_WITH_SEATS':
            case 'TABLE_RECTANGLE_WITH_SEATS': 
                return this.variant.opts.chairWidth; 
            default:
                return undefined;
        }
    }

    get chairHeight() {
        switch(this.variant.type) {
            case 'CHAIR_RECTANGLE': 
            case 'CHAIR_BANQUET':
                return this.variant.opts.height;
            case 'TABLE_CIRCLE_WITH_SEATS':
            case 'TABLE_RECTANGLE_WITH_SEATS': 
                return this.variant.opts.chairHeight; 
            default:
                return undefined;
        }
    }

    get tableColor() { return this.variant?.opts.tableColor; }
    get tableDiameter() { return this.variant?.opts.tableDiameter; }
    get numberOfChair() { return this.variant?.opts.numberOfChair; }
    get freeSpaces() { return this.variant?.opts.freeSpaces; }
    get chairTop() { return this.variant?.opts.chairTop; }
    get chairBottom() { return this.variant?.opts.chairBottom; }
    get chairLeft() { return this.variant?.opts.chairLeft; }
    get chairRight() { return this.variant?.opts.chairRight; }
    get diameter() { return this.variant?.opts.diameter; }
    get color() { return this.variant?.opts.color; }
    get width() { return this.variant?.opts.width; }
    get height() { return this.variant?.opts.height; }


    //
    // replace furniture
    //
    editVariantProperty (event) {
        logger.log(`editVariantProperty(${event.attribute}, ${event.value})`);

        const variant = VariantReplace.createModified(this.variant, event.attribute, event.value);
        this.replaceFurniture(variant);
    }

    replaceFurniture(variant) {
        if (variant) {
            const op = variant.create();
            this.placeSymbolsList.setPrototype(op);
            const info = BlockUtils.getSymbolInfo(op);
            this.grid.widthX = info.widthX;
            this.grid.widthY = info.widthY;
            this.variant = this.determineVariant(op);
            this.updateGrid(this.placeSymbolsList.block);
        }
    }
}    

/**
 * handle defining gaps
 * 
 * - picking single chairs sets these invisible
 * - selection chairs sets those invisible, which belong to the same block
 * - picking into the void inside the selection area does nothing but proceeds
 * - picking into the void ends the action completely
 * - picking or selection other items in the drawing are dispatched to the appropriate edit command
 * 
 * TODO: possibly create a filter instead of the CommandDispatcher
 * Attention: CommandDispatcher send null to let the dispatcher proceed, whereas Filter send null in 
 * order to stop evaluation!!
 */
class FltDefineGaps extends CommandDispatcher {
    constructor(blockId, selectGrafic) {
        super();

        this.blockId = blockId;
        this.selectGrafic = selectGrafic;
        this.hits = null;
        this.postponedBreak = false;
    }

    actionPoint (event) {
        console.log(`actionPoint`)
        // postpone the decision whether to set a chair invisible or to break the action
        // until actionPointUp, because actionSelection might interfere
        if (this.selectGrafic.hitsArea(event)) {
            this.hits = Pick.pick(event.view, event.raw, ['XOpSymbol']);
            return new CommandEvent('.FltPlaceBlock.remainInGapState');
        }

        this.postponedBreak = true;
        return new CommandEvent('.FltPlaceBlock.remainInGapState');
    }

    actionPointUp (event) {
        console.log(`actionPointUp hits=${this.hits} postponed=${this.postponedBreak}`)
        
        // no selection event has happened since actionPoint
        // apply hits from the pick point selection or ... 
        if (this.hits) {
            this.applyGapsOnCurrentBlockGroup(this.hits);
            return new CommandEvent('.FltPlaceBlock.remainInGapState');
        }

        //... leave because the user picked outside of the selection area
        if (this.postponedBreak) {
            this.postponedBreak = false;
            return new BreakEvent();
        }

        // let FltSelectAndDispatch decide
        return null;
    }

    actionSelection (event) {
        console.log(`actionSelection hits=${this.hits} postpone = ${this.postponedBreak}`)

        this.hits = null; // ignore any hits coming from actionPoint

        if (event.formation instanceof RectangleSelection) {
            // check if the selection rectangle is completely outside of the selection, then leave
            const hitArea = this.selectGrafic.boxHitsArea(event.formation.box);
            console.log(`hitArea ${hitArea}`)
            if (!hitArea) {
                this.postponedBreak = false;
                return new BreakEvent();
            }
        } else { // PickPointSelection
            if (this.postponedBreak) {
                this.postponedBreak = false;
                return new BreakEvent();
            }
        }

        this.postponedBreak = false;
        this.applyGapsOnCurrentBlockGroup(event.objects);
        return new CommandEvent('.FltPlaceBlock.remainInGapState');
    }

    /**
     * apply the gaps only on items in the current block
     * @param {*} objects 
     */
    applyGapsOnCurrentBlockGroup(objects) {
        for (const op of objects) {
            if(this.isInCurrentBlock(op)) {
                op.setVisibility(false);
                theApp.model.changed2d = true;
            }
        }
    }

    isInCurrentBlock(op) {
        const block = BlockUtils.findBlockGroup(op);
        return this.blockId === block?.id;
    }
}