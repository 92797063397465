<template>
    <div>
        <div Class="ve-FloorPlanButton pe-auto p-3 mb-5 bg-body-tertiary" style="width: 32px; height: 32px;"
            @click=onRectangularWalls>
            <svg width="72" height="72" viewBox="0 0 72 72" :fill=bg xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_230_275)">
                    <rect x="4" width="64" height="64" rx="5" fill="white" />
                    <rect x="4.5" y="0.5" width="63" height="63" rx="4.5" stroke="#D9D9D9" />
                </g>
                <rect x="15.6665" y="16.238" width="40.6667"  :fill=bg height="30" :stroke=icon stroke-width="2" />
                <circle cx="56" cy="17" r="3" fill="#D9D9D9" />
                <circle cx="16" cy="46" r="3" fill="#D9D9D9" />
                <defs>
                    <filter id="filter0_d_230_275" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_230_275" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_230_275" result="shape" />
                    </filter>
                </defs>
            </svg>
        </div>
        <div class="panel-rectangle border-bottom p-3">
            <div :hidden=hideSize id="wallParams">
                <fieldset class="ve-row">
                    <InputNumberWithUnit title=" " class="col-6" label="Länge" unit="cm" :scale="10" :min=1 :digits="1"
                        v-model="widthX" @update:modelValue="onChangeWidthX" />
                    <InputNumberWithUnit title=" " class="col-6" label="Breite" unit="cm" :scale="10" :min=1 :digits="1"
                        v-model="widthY" @update:modelValue="onChangeWidthY" />
                </fieldset>
            </div>
            <fieldset class="ve-row">
                <InputNumberWithUnit title=" " class="col-6" label="Wandstärke" unit="cm" :scale="10" :min=1 :digits="1"
                    v-model="thickness" @update:modelValue="onChangeThickness" />
                <!-- <InputButtonColor class="col-4 mt-2" label="Farbe" v-model="fill" @update:modelValue="onChangeFill" /> -->
            </fieldset>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue'
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue'

export default {
    name: 'PanelWall',
    components: {
        InputNumberWithUnit,
        InputButtonColor
    },
    data() {
        return {
            widthY: Settings.get('floorplan.rectangularWalls.widthX', 0),
            widthX: Settings.get('floorplan.rectangularWalls.widthY',0),
            thickness: Settings.get('floorplan.wall.thickness', 240),
            fill: Settings.get('floorplan.wall.fill', '#000000'),
            hideSize: true,
            icon: "black",
            bg: "white"
        }
    },
    mounted() {
        theApp.addDialog('PanelWall', this)
    },
    unmounted() {
        theApp.removeDialog('PanelWall')
    },
    methods: {
        update(action) {
            this.thickness = action.thickness
            this.widthY = action.widthY
            this.widthX = action.widthX
            this.fill = action.fill
            this.state = action.state

            // do not display size input field until user starts dragging
            this.hideSize = this.state === 0 // state 0 is 'FIRST_POINT', waiting that the user starts dragging a rectangle

            this.icon = "#009aa3"
            this.bg = "#f5f5f5"
        },
        onChangeWidthY() {
            theApp.executeValueEvent("widthY", this.widthY)
            //Settings.set('floorplan.rectangularWalls.widthY', this.widthY)
        },
        onChangeWidthX() {
            theApp.executeValueEvent("widthX", this.widthX)
            // Settings.set('floorplan.rectangularWalls.widthX', this.widthX)
        },
        onChangeThickness() {
            theApp.executeValueEvent("thickness", this.thickness)
            Settings.set('floorplan.wall.thickness', this.thickness)
        },
        onChangeFill() {
            theApp.executeValueEvent("fill", this.fill)
            Settings.set('floorplan.wall.fill', this.fill) 
        },
        onRectangularWalls() {
            theApp.executeCommand('.floorplan.rectangularWall')
        },
        setActiveButton() {
            this.icon = "#009aa3"
            this.bg = "#f5f5f5"
            
        },
        setDeactiveButton () {
            this.icon = 'black'
            this.bg = 'white'
        }

    },
    
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/ve-variables.scss';

.ve-iconbar-wall-icon {
    @if $iconbar-draw-button-show-text ==false {
        font-size: 16px;
    }
}

SVG {
    cursor: pointer;
}
</style>