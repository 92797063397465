import * as THREE from 'three';

import theApp from '@/frame/Application';

import Geometry from '@/visual-events/model/Geometry';
import OpReference from '@/visual-events/model/OpReference';
import OpShapePath from '@/visual-events/model/OpShapePath';
import Variant from '@/visual-events/model/Variant';
import { isEmpty } from '@/frame/Useful';

// buffers
const _v1 = new THREE.Vector3();
const _v2 = new THREE.Vector3();

/**
 * Utility class for defining walls and openings (doors, window)
 * 
 * some rules:
 * 
 * - the contours shall follow the recommended convention for outer and inner contours in SVG,
 *   because many viewers and tools follow this convention 
 *   i.e. define the outer contour clockwise and the holes counter clockwise
 * 
 * example:
 *    ^------------------------------------>
 *    |                                    |
 *    <------------------------------------v
 * 
 *  - define the openings according to this, i.e. set the reference point on the upper side
 *    of the wall covering part.
 *    This will ensure, that the actions, which snap to the wall's contour closest to the mouse position 
 *    place it correctly and - together with the first rule - choose the angle correctly
 * 
 *  example:
 * 
 *                        O mouse position
 *                    ___   ___
 *                   |   \ /   |
 *                   |    |    |  0°
 *    ^-------------------x------------------------------->
 *    |              |XXXXXXXXX|    |XXXXXXXXX|           |     
 *    <---------------------------------------------------v
 *                                  |    |    |  180°
 *                                  |___/ \___|
 * 
 *                                       O mouse position           
 */
export default class FloorPlan {

    /**
     * create the ShapePath for a rectangular floor plan
     * 
     *    ^------------------------------------>
     *    |                                    |
     *    |   <----------------------------^   |
     *    |   |                            |   |
     *    |   |                            |   |
     *    |   |                            |   |
     *    |   v---------------------------->   |
     *    |                                    |
     *    <------------------------------------v
     * 
     * @param {*} x 
     * @param {*} y 
     * @param {*} dX inner widthX
     * @param {*} dY inner widthY
     * @param {*} t thickness
     * @returns 
     */
    static createRectangularFloorPlanShapePath (x, y, dX, dY, t) {
        const path = new THREE.ShapePath();

       // outer contour (clock wise)
       // remark: 
       //TODO: provide an api for shapes, which encourages this rule
       path.moveTo(x,       y);
       path.lineTo(x + dX,  y);
       path.lineTo(x + dX,  y + dY);
       path.lineTo(x,       y + dY);
       path.lineTo(x,       y);
       path.currentPath.autoClose = true;

       // inner contour (counter clock wise)

       const inner = new THREE.ShapePath();
       inner.moveTo(x - t,        y - t);
       inner.lineTo(x - t,        y + t + dY);
       inner.lineTo(x + t + dX,   y + t + dY);
       inner.lineTo(x + t + dX,   y- t);
       inner.lineTo(x - t,        y - t);
       inner.currentPath.autoClose = true;

       const holes = inner.toShapes();
       path.subPaths.push(...holes);

       return path;
    }

    /**
     * retrieve the defining parameters of a rectangular floor plan
     * @param {*} op 
     * @returns {x, y, widthX, widthY, thickness}
     */
    static getSize (op) {
        //TODO: thickness should not rely on option, better on geometry
        const thickness = FloorPlan.getWallThickness (op)

        const box = Geometry.computeBox(op);
        const x = box.min.x + thickness;
        const y = box.min.y + thickness;
        const widthX = box.max.x - box.min.x - 2 * thickness;
        const widthY = box.max.y - box.min.y - 2 * thickness;

        return {x, y, widthX, widthY, thickness}
    }

    /**
     * a wall is an OpShapePath with attribute $wall
     * 
     * TODO: marking a wall should not require the explicit thickness arg
     * Instead evalute the geometry. 
     * @param {*} op 
     * @param {*} thickness 
     */
    static markAsWall (op, thickness) {

        const json = {
            version: 0,
            thickness: thickness
        }

        op.setAttribute('$wall', json);
    }

    static getWall (op) {
        return op.getAttribute('$wall');
    }

    // TODO: s. markAsWall
    static getWallThickness (op) {
        return op.getAttribute('$wall.thickness');
    }

    //---------------------------------------------------------------------------------------------------------------------------------------------
    // openings
    //---------------------------------------------------------------------------------------------------------------------------------------------

    /**
     * check, if the OpReference op is a door variant and retrieve the definition
     * @param {*} op 
     * @returns undefined, if not a door, the $variant otherwise
     */
    static getDoor (op) {
        if (!(op instanceof OpReference))
            return false;

        const symbolId  = op.symbolId;
        const symbol = theApp.model.symbols.get(symbolId);
        const variant = Variant.getVariant(symbol);
        return variant?.type === 'SHAPE_DOOR' ? variant : undefined;
    }

    /**
     * check, if the OpReference op is a window variant and retrieve the definition
     * @param {*} op 
     * @returns undefined, if not a window, the $variant otherwise
     */
    static getWindow (op) {
        if (!(op instanceof OpReference))
            return false;

        const symbolId  = op.symbolId;
        const symbol = theApp.model.symbols.get(symbolId);
        const variant = Variant.getVariant(symbol);
        return variant?.type === 'SHAPE_WINDOW' ? variant : undefined;
    }

    /**
     * create the swing range of a door 
     * 
     *   pivot left           right
     *        __               __
     *       |   \           /   |
     *   x,y |____|     x,y |____|
     * 
     * @param {*} x  left corner
     * @param {*} y 
     * @param {*} width 
     * @param {*} pivot 
     * @returns 
     */
    static createDoorSwing (x, y, r, pivot, sashY) {
        const path = new THREE.ShapePath();
        const subpath = new THREE.Path();
        if(pivot === 'left'){
            subpath.moveTo(x, sashY);
            subpath.lineTo(x, y);
            subpath.absarc(x, y, r, 0, Math.PI/2, false);
            subpath.lineTo(x, y);
            subpath.moveTo(x+r, y);
            subpath.lineTo(x+r, sashY);
        }else if(pivot == 'right'){
            subpath.moveTo(x + r, sashY);
            subpath.lineTo(x + r, y);
            subpath.absarc(x + r, y, r, Math.PI, Math.PI/2, true);
            subpath.lineTo(x + r, y);
            subpath.lineTo(x, y);
            subpath.lineTo(x, sashY);
        }else if(pivot == 'bottomLeft'){
            subpath.moveTo(x, sashY);
            subpath.lineTo(x, y);
            subpath.absarc(x, y, r, 0, 3*Math.PI/2, true);
            subpath.lineTo(x, y);
            subpath.moveTo(x+r, y);
            subpath.lineTo(x+r, sashY);
        }else if(pivot == 'bottomRight'){
            subpath.moveTo(x + r, sashY);
            subpath.lineTo(x + r, y);
            subpath.absarc(x + r, y, r, Math.PI, 3*Math.PI/2, false);
            subpath.lineTo(x + r, y);
            subpath.lineTo(x, y);
        }

        path.subPaths.push( subpath );

        const style = { fill: '#000000', fillOpacity: 1, stroke: '#000000', strokeLineCap: 'but', strokeLineJoin: 'miter', strokeMiterLimit: 4, strokeOpacity: 1, strokeWidth: 1}

        return new OpShapePath('OpShapePath', 'door', path, style);
    }

    /**
     * create an arc ShapePath and a line back to the center
     * 
     * @param {*} cx 
     * @param {*} cy 
     * @param {*} radius 
     * @param {*} startAngle 
     * @param {*} endAngle 
     * @param {*} clockwise 
     * @returns 
     */
    static createArc(cx, cy, radius, startAngle, endAngle, clockwise) {

        const path = new THREE.ShapePath();

        const subpath = new THREE.Path();

        subpath.moveTo(cx, cy);
        subpath.absarc (cx, cy, radius, startAngle, endAngle, clockwise);
        subpath.lineTo(cx, cy);

        path.subPaths.push( subpath );

        return path;
    }

    /**
     * find the closest point on the wall 'op' from position
     * and the angle of the wall at that point
     * 
     * TODO: elaborate closestTo for other types of curve; now only working on LineCurve
     * @param {*} op 
     * @param {*} position 
     * @returns { curve, x, y, distance, angle } if found, {} otherwise
     */

    static closestToWall (op, position) {
        const path = op.path;
        const subPaths = path.subPaths;

        const result = subPaths.reduce( (result, path) => {
            return path.curves.reduce( (r, c) => {
                if (c instanceof THREE.LineCurve) {
                    _v1.copy(c.v1);
                    _v1.z = 0;
                    _v2.copy(c.v2);
                    _v2.z = 0;
                    _v1.applyMatrix4(op.transform);
                    _v2.applyMatrix4(op.transform);
                    const { x, y, d } = Geometry.closestOnLine(_v1, _v2, position);
                    if (isEmpty(r) || d < r.distance)
                        return { curve: c, x: x, y: y, distance: d, angle: Geometry.angleOfLine(_v1, _v2) };
                }
                return r;
            }, result )
        }, {} );

        return result;
    }

}