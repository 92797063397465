import Command from '@/frame/Command';
import CommandPool from '@/frame/CommandPool';

import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';

import CameraUtils from '@/visual-events/model/CameraUtils';

export default class CommandPoolView extends CommandPool {
  constructor () {
    super(CommandPool.root, 'planer', [
      new Command('minimize2D', args => this.doMinimize2D(args)),
      new Command('maximize2D', args => this.doMaximize2D(args)),
      new Command('minimize3D', args => this.doMinimize3D(args)),
      new Command('maximize3D', args => this.doMaximize3D(args)),
      new Command('splitVertically', args => this.doSplitVertically(args)),
      new Command('splitHorizontally', args => this.doSplitHorizontally(args)),
      new Command('storeInitialCameraPosition3D', args => this.doStoreInitialCameraPosition3D(args)),
      new Command('restoreInitialCameraPosition3D', args => this.doRestoreInitialCameraPosition3D(args)),
      new Command('initCameraPosition', args => this.doInitCameraPosition(args)),
      new Command('simulateKey', args => this.doSimulateKey(args)),
      new Command('togglePlaneMode', args => this.doTogglePlaneMode(args)),
    ]);
  }

  doMinimize2D(args) {
    // if 2D is maximized, show both views
    if (Settings.getOption('views', '2D Ansicht', 'display') &&
       !Settings.getOption('views', '3D Ansicht', 'display')) {
        Settings.setOption('views', '3D Ansicht', 'display', true);
    } else {
      // at all time one of the grafic views must be open
      if (!Settings.getOption('views', '3D Ansicht', 'display'))
          Settings.setOption('views', '3D Ansicht', 'display', true);

      Settings.setOption('views', '2D Ansicht', 'display', false);
    }

    this.updateViewLayout();
  }

  doMinimize3D(args) {
    // if 3D is maximized, show both views
    if (Settings.getOption('views', '3D Ansicht', 'display') &&
       !Settings.getOption('views', '2D Ansicht', 'display')) {
        Settings.setOption('views', '2D Ansicht', 'display', true);
    } else {
      // at all time one of the grafic views must be open
      if (!Settings.getOption('views', '2D Ansicht', 'display'))
          Settings.setOption('views', '2D Ansicht', 'display', true);

      Settings.setOption('views', '3D Ansicht', 'display', false);
    }

    this.updateViewLayout();
  }

  doMaximize2D(args) {
    // maximizing means: close the other
    if (Settings.getOption('views', '3D Ansicht', 'display'))
        Settings.setOption('views', '3D Ansicht', 'display', false);

    Settings.setOption('views', '2D Ansicht', 'display', true);

    this.updateViewLayout();
  }

  doMaximize3D(args) {
    // maximizing means: close the other
    if (Settings.getOption('views', '2D Ansicht', 'display'))
        Settings.setOption('views', '2D Ansicht', 'display', false);

    Settings.setOption('views', '3D Ansicht', 'display', true);

    this.updateViewLayout();
  }

  doSplitVertically(args) {
    Settings.set('layout.split2D3Dvertically', true)

    this.updateViewLayout();
  }

  doSplitHorizontally(args) {
    Settings.set('layout.split2D3Dvertically', false)

    this.updateViewLayout();
  }

  updateViewLayout() {
    const split2D3Dvertically = Settings.get('layout.split2D3Dvertically');
    theApp.findDialogByName('main')?.setSplit2D3Dvertically(split2D3Dvertically);
    const display2D = Settings.getOption('views', '2D Ansicht', 'display');
    const display3D = Settings.getOption('views', '3D Ansicht', 'display');
    theApp.findDialogByName('main')?.setViewLayout(display2D, display3D);

  }

  doStoreInitialCameraPosition3D(args) {
    const view3D = theApp.findViewByName('3D Ansicht')
    CameraUtils.storeInitialCameraPosition(view3D);
  }

  doRestoreInitialCameraPosition3D(args) { 
    const view3D = theApp.findViewByName('3D Ansicht')
    CameraUtils.restoreInitialCameraPosition(view3D);
  }

  doInitCameraPosition(args) {
    const view3D = theApp.findViewByName('3D Ansicht')
    view3D.initCameraPosition();
  }

  doSimulateKey(args) {
    const key = args[1];
    const type = args[2];
    if (key) {
      var evt = new KeyboardEvent(type, { bubbles: true, cancelable: true, view: window, code: key });
      document.body.dispatchEvent(evt);
    }
  }

  doTogglePlaneMode(args) {
    const view3D = theApp.findViewByName('3D Ansicht');
    view3D.controls.toggleLookVertical();

    const iconBarControls3D = theApp.findDialogByName('IconBarControls(3D Ansicht)');
    iconBarControls3D.toggleButton('Plane');
    iconBarControls3D.toggleButton('Person');
  }
}