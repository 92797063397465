import * as THREE from 'three';
import { nextTick } from 'vue'

import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import { BreakEvent } from '@/frame/Event.js';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Geometry from '@/visual-events/model/Geometry';
import OpFactory from '@/visual-events/model/OpFactory';
import OpUtils from '@/visual-events/model/OpUtils';
import Settings from '@/visual-events/data/Settings';
import ShapeUtils from '@/visual-events/model/ShapeUtils';
import Logger from '@/frame/Logger';

const logger = new Logger('ActRectangle');

const State = Object.freeze({
    FIRST_POINT: 0,
    DRAG: 1,
    EDIT: 2
  });
  
export default class ActRectangle extends Action {
    constructor(args) {
      super();

      this.args = args;

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.useStroke = Settings.get('style.useStroke', true);
      this.stroke = Settings.get('style.stroke', '#000000');
      this.strokeWidth = Settings.get('style.strokeWidth');
      this.useFill = Settings.get('style.useFill', true);
      this.fill = Settings.get('style.fill', '#222222');

      this.angle = 0;
      this.width = Settings.get('rectangle.width');
      this.height = Settings.get('rectangle.height');

      // corners and temporary grafic while creating a rectangle
      this.position1 = new THREE.Vector3(0, 0, 0);
      this.position2 = new THREE.Vector3(0, 0, 0);
      this.grafic = null;

      // working matrix
      this.t = new THREE.Matrix4();
      this.tdiff = new THREE.Matrix4();
      this.c = new THREE.Vector3();

      this.objects = [];
      if (args.length>1)
        this.objects = args[1];

      this.state = this.objects.length > 0 ? State.EDIT : State.FIRST_POINT;
    }

    actionStart () {
        logger.log(`actionStart`);
        if (this.state === State.EDIT)
            this.addFltSelectGrafic();
        else 
            this.addFilter(new FltPointDef());

        this.evaluateSelection();
        this.connectToGUI();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);
        this.resetCursor();
        this.clearTemporaryGrafic();
        this.disconnectFromGUI();
    }

    actionPoint (event) {
        logger.log(`actionPoint (${event.p[0]}, ${event.p[1]})`);
        switch (this.state) {
            case State.FIRST_POINT: {
                this.position1.x = event.p[0];
                this.position1.y = event.p[1];
                
                this.changePanel();

                this.angle = Settings.get('rectangle.angle');
                this.width = Settings.get('rectangle.width');
                this.height = Settings.get('rectangle.height');
            
                this.position2.x = this.position1.x + this.width;
                this.position2.y = this.position1.y + this.height;

                this.showTemporaryGrafic();
                this.state= State.DRAG;
                break;
            }
            case State.DRAG:
                // does not happen
                break;
            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }

        this.handleCameraControlsEnableState();

        return null;
    }


    actionDynamic (event) {
        logger.log(`actionDynamic`);
        switch (this.state) {
            case State.FIRST_POINT:
                // ignored
                break;
            case State.DRAG: {
                this.position2.x = event.p[0];
                this.position2.y = event.p[1];

                this.height = Math.abs(this.position2.x - this.position1.x);
                this.width = Math.abs(this.position2.y - this.position1.y);
                this.angle = 0;

                const panel = theApp.findDialogByName('PanelRectangle');
                panel?.update(this);

                this.adaptTemporaryGrafic();
                theApp.model.changed2d = true; //op;

                this.state= State.DRAG;
                break;
            }
            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }
        return null;
    }

    actionPointUp (event) {
        logger.log(`actionPointUp ${this.state}`);
        switch (this.state) {
            case State.FIRST_POINT:
                // does not happen
                break;
            case State.DRAG: {
                // do not update width and height on PointUp:
                // If the PointUp follows on PointDown without intermediate Dynamic events, 
                // the desired behavior is, that the initial size is kept.
                // In effect, the rectangle is just placed into the clicked position.

                // this.position2.x = event.p[0];
                // this.position2.y = event.p[1];

                // this.height = Math.abs(this.position2.x - this.position1.x);
                // this.width = Math.abs(this.position2.y - this.position1.y);
                // this.angle = 0;

                // this.adaptTemporaryGrafic();

                // this.panelRectangle?.update(this);

                const op = this.finishRectangle();

                // proceed with editing
                this.objects.push(op);
                this.disconnectFromGUI();
                this.state = State.EDIT;
                this.addFltSelectGrafic();
                this.connectToGUI();
                
                break;
            }
            case State.EDIT:
                // handled by FltSelectGrafic
                break;
        }

        this.handleCameraControlsEnableState();

        return null;
    }

    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'useStroke') {
            this.useStroke = event.value;
            this.applyStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'stroke') {
            this.stroke = event.value;
            this.applyStyle({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'strokeWidth') {
            this.strokeWidth = event.value;
            this.applyStyle({ strokeWidth: this.strokeWidth });
            done = true;
        }

        if (event.attribute === 'useFill') {
            this.useFill = event.value;
            this.applyStyle({ fill: (this.useFill ? this.fill : 'none') });
            done = true;
        }
        
        if (event.attribute === 'fill') {
            this.fill = event.value;
            this.applyStyle({ fill: (this.useFill ? this.fill : 'none') });
            done = true;
        }

        if (event.attribute === 'width') {
            this.width = event.value;
            this.adaptSize(this.width, this.height);
            this.adaptTemporaryGrafic();
            done = true;
        }

        if (event.attribute === 'height') {
            this.height = event.value;
            this.adaptSize(this.width, this.height);
            this.adaptTemporaryGrafic();
            done = true;
        }

        if (event.attribute === 'angle') {
            this.angle = event.value;
            this.adaptAngle();
            this.adaptTemporaryGrafic();
            done = true;
        }

        return done ? null : event;
    }

    actionCommand(event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.FIRST_POINT:
                // does not happen
                break;
            case State.DRAG:
                // does not happen
                break;
            case State.EDIT: {
                switch (cmd) {
                    case '.select.delete': {
                        this.deleteSelection();
                        return new BreakEvent();
                    }
                    case '.select.copy': {
                        logger.log('.select.copy')
                        this.copySelection();
                        this.connectToGUI();
                        return null;
                    }
                    case '.select.applyTransform': {
                        const tdiff = event.args[0];
                        for (const op of this.objects)
                            op.applyTransform(tdiff);
                        this.angle = this.objects.length == 1 ? Geometry.getRotationAngle(this.objects[0].transform) : this.getFilter().getRotationAngle();
                        theApp.findDialogByName('PanelRectangle')?.update(this);
                        return null;
                    }
                    case '.select.dragBoxPoint': {
                        const box = event.args[1];

                        this.height = Math.abs(box.max.y - box.min.y);
                        this.width  = Math.abs(box.max.x - box.min.x);
                        //this.angle  = 0;

                        logger.log(`${this.width} ${this.height}`)

                        const op = this.objects[0];
                        const path = ShapeUtils.createRectanglePath(box.min.x, box.min.y, this.width, this.height);
                        op.setShapePath(path);
        
                        const panel = theApp.findDialogByName('PanelRectangle');
                        panel?.update(this);
        
                        theApp.model.changed2d = true; //op;
                        return null;
                    }
                }
            } // case EDIT
        } // switch state

        return event;
    }

    /**
     * on delete command delete the selected objects 
     * and go into State.FIRST_POINT
     */
    deleteSelection () {
        OpUtils.deleteSelection(this.objects);
        theApp.model.changed2d = true;

        // State change to State.FIRST_POINT
        this.objects = [];
        this.clearTemporaryGrafic();
        this.removeFilter();
        this.disconnectFromGUI();
        this.state = State.FIRST_POINT;
        this.addFilter(new FltPointDef());
        this.connectToGUI();
        this.handleCameraControlsEnableState();
    }

    copySelection () {
        const dist = Settings.get('selection.grafic.iconDist', 300);
        this.objects = OpUtils.copySelection (this.objects, this.root2D, dist);

        this.disconnectFromGUI();
        this.state = State.EDIT;
        this.getFilter().shift(dist);

        theApp.model.changed2d = true;
    }

    /**
     * transfer the rectangle currently beeing generated from the temporary grafic
     * into the model.
     * @returns 
     */
    finishRectangle () {
        const op = this.grafic;
        this.grafic = null; // clear temporary grafic
        this.root2D.add(op);
        return op;
    }

    /**
     * while editing FltSelectGrafic does most of the job
     */
    addFltSelectGrafic () {
        if (this.objects.length === 1) {
            const box = Geometry.computeBox(this.objects[0]);
            const transform = this.objects[0].transform;
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon().useBoxPoints());
        } else if (this.objects.length > 1) {
            const box = Geometry.computeBoxUnion(this.objects);
            const transform = new THREE.Matrix4(); // identity -> axis parallel
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon());
        }
    }

    showTemporaryGrafic () {
        switch (this.state) {
            case State.FIRST_POINT: {
                const points =this.normalizeVertices(this.position1, this.position2);
            
                this.grafic = OpFactory.createPolygon(points);
                const style = { 
                    fill: this.useFill ? this.fill : 'none', 
                    fillOpacity: 1, 
                    stroke: this.useStroke ? this.stroke : 'none', 
                    strokeLineCap: 'but', 
                    strokeLineJoin: 'miter', 
                    strokeMiterLimit: 4, 
                    strokeOpacity: 0, 
                    strokeWidth: this.strokeWidth
                };
                this.grafic.setStyle(style);
                this.root2D.add(this.grafic);
                theApp.model.changed2d = true; //op;
                break;
            }
            case State.DRAG:
                // does not happen
                break;
            case State.EDIT:
                // does not happen
                break;
        }
    }

    clearTemporaryGrafic () {

        switch (this.state) {            
            case State.FIRST_POINT: 
            case State.DRAG: {
                if (this.grafic) {
                    this.root2D.remove(this.grafic);
                    this.grafic = null;
                    theApp.model.changed2d = true; //op;
                }
                break;
            }
        }
    }

    adaptTemporaryGrafic (optsStyle) {
        switch (this.state) {
            case State.FIRST_POINT:
                // does not happen
                break;
            case State.DRAG: {
                const points =this.normalizeVertices(this.position1, this.position2);
            
                const op = this.grafic;
                const path = ShapeUtils.createPolygonPath(points, true);
                op.setShapePath(path);
        
                if (optsStyle)
                    op.setStyle(optsStyle);

                theApp.model.changed2d = true; //op;
                break;
            }
            case State.EDIT:
                // does not happen
                break;
        }
    }

    /**
     * normalize the cursor position in that way, that the rectangle's 
     * width and height have positive sign, i.e. in angle 0° it is always
     * left bottom to right top ordered.
     *  
     *  .________x
     *           |
     *  x________.
     * 
     * This corresponds with the result of ShapeUtils.getRectangle(op)
     * @param {*} pos1 
     * @param {*} pos2 
     * @returns 
     */
    normalizeVertices (pos1, pos2) {
        const minX = Math.min(pos1.x,pos2.x);
        const maxX = Math.max(pos1.x,pos2.x);
        const minY = Math.min(pos1.y,pos2.y);
        const maxY = Math.max(pos1.y,pos2.y);
        return [
            [minX, minY],
            [maxX, minY],
            [maxX, maxY],
            [minX, maxY]
        ];
    }

    /**
     * retrieve the defining parameters and the style from selected
     * rectangles. The latest value wins 
     * TODO: introduce 'differing values' handling
     */
    evaluateSelection () {

        //TODO: wenn unterschiedliche Styles und Abmessungen, dann was???
        this.angle = this.objects.length == 1 ? Geometry.getRotationAngle(this.objects[0].transform) : 0;
        this.objects.forEach(op => {
            const style = op.style;
            this.useStroke = style.stroke !== 'none';
            this.stroke = style.stroke;
            this.strokeWidth = style.strokeWidth;
            this.useFill =  style.fill !== 'none';
            this.fill = style.fill;

            const rectangle  = ShapeUtils.getRectangle(op);
            this.width = rectangle.width;
            this.height = rectangle.height;
        });
    }

    applyStyle (optsStyle) {
        switch (this.state) {
            case State.EDIT: 
                this.adaptSelectedObjects(optsStyle);
                break;
            default:
                this.adaptTemporaryGrafic(optsStyle);
                break;
        }
    }

    adaptSelectedObjects (optsStyle) {
        if (optsStyle) {
            this.objects.forEach(op => op.setStyle(optsStyle));
            theApp.model.changed2d = true;
        }
    }

    adaptSize () {
        this.objects.forEach(op => { 
            const rectangle = ShapeUtils.getRectangle(op);
            const path = ShapeUtils.createRectanglePath(rectangle.x, rectangle.y, this.width, this.height);
            op.setShapePath(path); 
        });
        theApp.model.changed2d = true;
        
        if (this.objects.length === 1)
            this.getFilter().update(this.objects[0].computeBox(), undefined);
        else
            this.getFilter().update(Geometry.computeBoxUnion(this.objects));
    }

    adaptAngle () {
        this.getFilter().getCenter(this.c);
        const diff = this.angle - this.getFilter().getRotationAngle();
        Geometry.makeRotationZ(this.tdiff, this.c, diff);
        this.objects.forEach(op => { 
            op.applyTransform(this.tdiff);
        });
        theApp.model.changed2d = true;
        
        if (this.objects.length === 1)
            this.getFilter().update(this.objects[0].computeBox(), this.objects[0].transform);
        else
            this.getFilter().applyTransform(this.tdiff);
    }

    /**
     * while dragging disable the CameraControls
     */
    handleCameraControlsEnableState() {
        switch (this.state) {
            case State.DRAG: 
            {
                this.view2D.disableCameraControls();
                break;
            }
            default: 
            {
                this.view2D.enableCameraControls();
                break;
            }
        }
    }

    resetCursor () {
        theApp.findDialogByName('main')?.setCursor(undefined);
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(this.state === State.EDIT ? 'Select' : 'Draw');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PanelDraw');
        nextTick(() => {
            // access updated DOM
            const panelDraw = theApp.findDialogByName('PanelDraw');
            if (panelDraw) {
                panelDraw.header = this.state === State.EDIT  ? "Rechteck bearbeiten" : "Rechteck erzeugen"
                panelDraw.showIcons = this.state !== State.EDIT;
                panelDraw.setTab('rectangle');
                nextTick(() => {
                    const panelDrawRectangle =  theApp.findDialogByName('PanelDrawRectangle');
                    panelDrawRectangle.editMode = this.state === State.EDIT;
                    const panelStyle = theApp.findDialogByName('PanelStyle');
                    panelStyle.update(this);
                    const panelRectangle = theApp.findDialogByName('PanelRectangle');
                    panelRectangle.update(this);
                })
            }
        })
    }

    changePanel() {
        const panelDraw = theApp.findDialogByName('PanelDraw');
        panelDraw.header = this.state === State.FIRST_POINT  ? "Rechteck bearbeiten" : "Rechteck erzeugen"
        panelDraw.showIcons = this.state !== State.FIRST_POINT;
        const panelDrawRectangle =  theApp.findDialogByName('PanelDrawRectangle');
        panelDrawRectangle.editMode = this.state === State.FIRST_POINT;
        const panelStyle = theApp.findDialogByName('PanelStyle');
        panelStyle.update(this);
        const panelRepanelDrawRectangle = theApp.findDialogByName('PanelRectangle');
        panelRepanelDrawRectangle.update(this);
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}    
