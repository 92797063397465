import * as THREE from 'three';

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { CADdySVGLoader } from '@/visual-events/loader/CADdySVGLoader.js';
import FileService from '@/visual-events/RestAPI/FileService';

import Settings from '@/visual-events/data/Settings';
import Logger from '../../frame/Logger';
import { font } from '../../assets/fonts/OpenSans_Regular';

const logger = new Logger('GrfUtils');


/**
 * utility class with convenience methods to create three.js 3D objects
*/
export default class GrfUtils {

  static addSquare (view, size) {
    const geometry = new THREE.BufferGeometry();
    // create a simple square shape. We duplicate the top left and bottom right
    // vertices because each vertex needs to appear once per triangle.
    const vertices = new Float32Array([
      -1.0, -1.0, 1.0,
      1.0, -1.0, 1.0,
      1.0, 1.0, 1.0,

      1.0, 1.0, 1.0,
      -1.0, 1.0, 1.0,
      -1.0, -1.0, 1.0
    ]);

    // itemSize = 3 because there are 3 values (components) per vertex
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

    const material = new THREE.MeshBasicMaterial({ color: 0xff00ff });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.scale.x = size;
    mesh.scale.y = size;
    mesh.scale.z = size;
    view.scene.add(mesh);

    const edges = new THREE.EdgesGeometry(geometry);
    const line = new THREE.Line(edges, new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 1 }));
    mesh.scale.x = size;
    mesh.scale.y = size;
    mesh.scale.z = size;
    view.scene.add(line);
  }

  static addLine (view, point1, point2, style) {
    const points = [point1, point2];
    const geometryPoints = new THREE.BufferGeometry().setFromPoints(points);

    const line = new THREE.Line(geometryPoints, new THREE.LineBasicMaterial(style));

    view.scene.add(line);
  }

  /**
   * create a hexagonal shape in x,z plane
   * @param {*} view
   * @param {*} position
   * @param {*} size
   * @param {*} OFFSETX
   * @param {*} OFFSETZ
   * @param {*} color
   */
  static addHexagon (view, position, size, OFFSETX, OFFSETZ, styleArea, styleOutline) {
    const shape = new THREE.Shape()
      .moveTo(-size, 0)
      .lineTo(-OFFSETX, OFFSETZ)
      .lineTo(OFFSETX, OFFSETZ)
      .lineTo(size, 0)
      .lineTo(OFFSETX, -OFFSETZ)
      .lineTo(-OFFSETX, -OFFSETZ)
      .lineTo(-size, 0); // close path

    const material = new THREE.MeshBasicMaterial(styleArea);
    const geometry = new THREE.ShapeGeometry(shape);

    geometry.rotateX(-Math.PI / 2);

    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.x = position.x;
    mesh.position.y = position.y;
    mesh.position.z = position.z;

    view.scene.add(mesh);

    shape.autoClose = true;
    const points = shape.getPoints();
    const geometryPoints = new THREE.BufferGeometry().setFromPoints(points);

    // rotate into xz-plane
    geometryPoints.rotateX(-Math.PI / 2);

    // solid line
    const line = new THREE.Line(geometryPoints, new THREE.LineBasicMaterial(styleOutline));

    line.position.x = position.x;
    line.position.y = position.y + 1; // avoid z-fighting lines with faces
    line.position.z = position.z;

    view.scene.add(line);
  }

  static addCircle (view, position, size, styleArea, styleOutline) {
    const radius = size / 2;
    var shape = new THREE.Shape()
      .moveTo(0, radius)
      .absarc(0, 0, radius, 0, 2 * Math.PI, true);

    const material = new THREE.MeshBasicMaterial(styleArea);
    const geometry = new THREE.ShapeGeometry(shape);

    geometry.rotateX(-Math.PI / 2);

    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.x = position.x;
    mesh.position.y = position.y;
    mesh.position.z = position.z;

    view.scene.add(mesh);

    shape.autoClose = true;
    const points = shape.getPoints();
    const geometryPoints = new THREE.BufferGeometry().setFromPoints(points);

    // rotate into xz-plane
    geometryPoints.rotateX(-Math.PI / 2);

    // solid line
    const line = new THREE.Line(geometryPoints, new THREE.LineBasicMaterial(styleOutline));

    line.position.x = position.x;
    line.position.y = position.y + 1; // avoid z-fighting lines with faces
    line.position.z = position.z;

    view.scene.add(line);
  }

  /**
   * addLabel and addText require a font, which should be loaded only once in the beginning
   */
  static font = null;
  static alreadyTriedToLoad = false;

  //TODO: FontManager, dictionary mit geladenen Fonts
  static async loadFont (name) {
    logger.log(`loadFont(${name})`);

    const urlFont = Settings.get('.urlFileService') + '/files/fonts/' + name;

    GrfUtils.font = undefined;
    const loader = new FontLoader();
    loader.requestHeader = FileService.requestHeader();
    loader.loadAsync(urlFont).then (
      font => {
        logger.log(`font ${name} loaded`);
        GrfUtils.font = font;
      }
    ).catch (
      err => {
        console.log( `could not load font ${name}:\n${err.message}`);
        GrfUtils.font = undefined;

        if (!GrfUtils.alreadyTriedToLoad && !GrfUtils.font) {
            logger.log('createLabel  Font not yet available. Using fallback');
            const loader = new FontLoader();
            GrfUtils.font = loader.parse(font);
            console.log(`GrfUtils.font = ${GrfUtils.font}`);
        }
    
        GrfUtils.alreadyTriedToLoad = true;
      }
    )

  }

  /**
   * create a shape text in the x,z-plane
   * @param {*} view
   * @param {*} text
   * @param {*} position
   * @param {*} size
   * @param {*} color
   */
  static addLabel (view, text, position, size, style) {
    // logger.log(`addLabel ${position.x} ${position.y} ${position.z}`)

    const group = GrfUtils.createLabel(text, position, size, style);
    if (group) {
      group.rotateX(-Math.PI / 2);
      view.scene.add(group);
    }
  }

  /**
   * TODO: Reconsider the GrfUtils design: factory class(es)? E.g. avoid area/outline generation for labels, if not required
   * 
   * @param {*} text 
   * @param {*} position 
   * @param {*} size 
   * @param {*} styleArea 
   * @param {*} styleOutline 
   * @param {*} textAnchor 
   * @param {*} baseLine 
   * @returns group with mesh and outline
   */
  static createLabel (text, position, size, style, textAnchor='start', baseLine='baseline') {
    logger.log(`createLabel ${position.x} ${position.y} ${position.z}`);
  
    const [x, y, z] = [position.x, position.y, position.z];

    const matLine = GrfUtils.createLineBasicMaterialForSVGStyle(style);

    const matArea = GrfUtils.createMeshBasicMaterialForSVGStyle(style);

    const shapes = GrfUtils.font.generateShapes(text, size);

    const geometry = new THREE.ShapeGeometry(shapes);

    geometry.computeBoundingBox();

    //logger.log(`${geometry.boundingBox.min.x}  ${geometry.boundingBox.max.x} x ${geometry.boundingBox.min.y}  ${geometry.boundingBox.max.y}`);

    let xOffset = 0;
    switch (textAnchor) {
      case 'start': - geometry.boundingBox.min.x
        break;
      case 'middle':
        xOffset = - 0.5 * (geometry.boundingBox.max.x + geometry.boundingBox.min.x);
        break;
      case 'end':
        xOffset = - geometry.boundingBox.max.x;
        break;
    }

    //TODO: dominantBaseline auto, mathematical, text-top u.a. fehlen, auto hängt vom writing-mode ab
    let yOffset = 0;
    switch (baseLine) {
      default:
      case 'baseline': //TODO: gibt es eigentlich nicht, CADdy++ SVG Export fehlerhaft?
        break;
      case 'middle':
        yOffset = - 0.5 *size;
        break;
      case 'hanging':
        yOffset = - size;
        break;
    }    
    
    geometry.translate(x + xOffset, y + yOffset, 10); // z=10 for higher priority

    const group = new THREE.Group();

    const mesh = matArea ? new THREE.Mesh(geometry, matArea) : null;

    const lineText = matLine ? new THREE.Object3D() : null;
    
    if (matLine) {
      const holeShapes = [];

      for (var i = 0; i < shapes.length; i++) {
        const shape = shapes[i];

        if (shape.holes && shape.holes.length > 0) {
          for (var j = 0; j < shape.holes.length; j++) {
            const hole = shape.holes[j];
            holeShapes.push(hole);
          }
        }
      }

      shapes.push.apply(shapes, holeShapes);

      for (let i = 0; i < shapes.length; i++) {
        const shape = shapes[i];

        const points = shape.getPoints();
        const geometry = new THREE.BufferGeometry().setFromPoints(points);

        geometry.translate(x + xOffset, y + yOffset, 20); // z=20 for higher priority

        const mesh = new THREE.Line(geometry, matLine);
        lineText.add(mesh);
      }
    }

    if (mesh)
      group.add(mesh);

    if (lineText)
      group.add(lineText);

    return group;
  }

  /**
   * convert SVGStyles as imported by CADdySVGLoader into feasible three.js materials
   * TODO: LineBasicMaterial is actually not used in OpShapePath (because of problems with linewidth?) 
   * TODO: rework outlines and line patterns like dotted and dashed lines
   * @param {*} SVG style 
   * @returns 
   */
  static createMeshBasicMaterialForSVGStyle(style) {

    if (style.fill === 'none')
      return null;

    if (style.visibility == 'hidden')
      return null;

    return new THREE.MeshBasicMaterial({
      color: new THREE.Color().setStyle( style.fill ),
      opacity: style.fillOpacity,
      transparent: style.fillOpacity !== 1
    });
  }

  //TODO: bring createMaterial and createMeshBasicMaterialForSVGStyle together
  static createMaterial (color, opacity) {
    return new THREE.MeshBasicMaterial( {
        color: new THREE.Color().setStyle( color ),
        opacity: opacity,
        transparent: true,
        side: THREE.DoubleSide,
        depthWrite: false,
        wireframe: false
    } );
  }

  static createLineBasicMaterialForSVGStyle(style) {
    if (style.stroke === 'none')
      return null;

    return new THREE.LineBasicMaterial({
      color: style.stroke,
      linewidth: style.strokeWidth,
      linecap: style.strokeLineCap, // e.g.'round', ignored by WebGLRenderer
      linejoin:  style.strokeLineJoin, // e.g. 'round', ignored by WebGLRenderer
    });
  }

  /**
   * create 3D solids with text shape
   * @param {*} view 
   * @param {*} text 
   * @param {*} position 
   * @param {*} height 
   * @param {*} size 
   * @returns 
   */
  static addText (view, text, position, size, height) {
    // logger.log(`add3DText ${position.x} ${position.y} ${position.z}`)
    if (GrfUtils.font === null) {
      logger.log('waiting for font');
      return;
    }

    const [x, y, z] = [position.x, position.y, position.z];

    const geometry = new TextGeometry(text, {
      size: size,
      height: height, // extrusion
      curveSegments: 6,
      font: GrfUtils.font
    });

    geometry.rotateX(-Math.PI / 2);

    const color = new THREE.Color();
    color.setRGB(200, 0, 0);
    const material = new THREE.MeshBasicMaterial({ color: color });
    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.x = x;
    mesh.position.y = y;
    mesh.position.z = z;

    view.scene.add(mesh);
  }

  static createShapePath(op, scale, grfStrategy) {
    const path = op.path;
    if (!path)
      return null;

    const fillColor = grfStrategy.fillColor(op);
    const fillOpacity = grfStrategy.fillOpacity(op);

    const strokeColor = grfStrategy.strokeColor(op);
    const strokeOpacity = grfStrategy.strokeOpacity(op);

    // generate the meshes making up the grafic of each OpObject
    const group = new THREE.Group();

    if (fillColor && fillColor !== 'none' && fillOpacity && fillOpacity !== 'none') {
        const fillMaterial = GrfUtils.createMaterial(fillColor, fillOpacity);

        const shapes = CADdySVGLoader.createShapes( path );

        for ( let j = 0; j < shapes.length; j ++ ) {
            const shape = shapes[ j ];
            const geometry = new THREE.ShapeGeometry( shape );
            const mesh = new THREE.Mesh( geometry, fillMaterial );
            group.add( mesh );
        }
    }

    if (strokeColor && strokeColor !== 'none' && strokeOpacity && strokeOpacity !== 'none') {
        //not LineBasicMaterial: outlines are also triangled faces created in SVGLoader.pointsToStroke
        const strokeMaterial = GrfUtils.createMaterial(strokeColor, strokeOpacity);

        for ( let j = 0, jl = path.subPaths.length; j < jl; j ++ ) {
            const subPath = path.subPaths[ j ];
            // to achieve a scale independent stroke-width, apply current scale and restore afterwards
            const strokeWidth = op.style.strokeWidth;
            op.style.strokeWidth = strokeWidth / scale * 0.2; //apply magic number, too ugly otherwise
            const geometry = CADdySVGLoader.pointsToStroke( subPath.getPoints(), op.style );
            op.style.strokeWidth = strokeWidth;
            if ( geometry ) {
                const mesh = new THREE.Mesh( geometry, strokeMaterial );
                group.add( mesh );
            }
        }
    }

    return group;
  }

}
