import { Vector3, Matrix3 } from 'three';
import Geometry from '@/visual-events/model/Geometry';
import { GridMode, AlternateMode } from '@/visual-events/actions/GridProvider';
import OpReference from '@/visual-events/model/OpReference'
import OpGroup from '@/visual-events/model/OpGroup'
import OpFactory from '@/visual-events/model/OpFactory'
import { rad2Deg, deg2Rad, periodic, equals, equalsZero } from '@/frame/Useful'

const _v = new Vector3();

const getRow = (block, i) => block.children.filter(op => op instanceof OpGroup)[i];
const getSeat = (row, j) => row.children.filter(op =>  op instanceof OpReference)[j];

function getCntX (block) {
    const rows = block.children.filter(op => op instanceof OpGroup);
    
    let cntX = 0;
    for (const row of rows) {
        const items = row.children.filter(op => op instanceof OpReference);
        const cnt = items.length;
        cntX = Math.max (cntX, cnt);
    }
    return cntX;
}

/**
 * furniture such as seats are placed in placing blocks, subdivided in rows
 * 
 *            block
 *   row    row    row    row
 *  s s s  s s s  s s s  s s s
 * 
 * In order to identify this kind of structure in the model the root group
 * is marked with a JSON attribute named '$PlacingBlock'.
 * 
 * Lateron, additional information about placing parameters, which are not easily 
 * recovarable by analysing the children and their positions, might be added to
 * the JSON object. 
 */
export default class BlockUtils {

    /**
     * factory method to create a placing block root
     */
    static createBlockGroup () {
        const op = OpFactory.createGroup('block');
        const info = {
            version: 0
        }
        op.setAttribute('$PlacingBlock', info);
        return op;
    }

    /**
     * check, whether op is the root of a placing group
     * @param {*} op 
     * @returns 
     */
    static isBlockGroup (op) {
        return op instanceof OpGroup && op.getAttribute('$PlacingBlock');
    }

    /**
     * store defining parameters in the $PlacingBlock attached to the block root op
     * @param {*} op 
     * @param {*} grid 
     * @param {*} renumberTool 
     */
    static storeBlockParameters(block, grid, renumberTool) {
        const info = {
            version: 1,
            grid: {
                mode: grid.mode,
                cntX: grid.cntX,
                cntY: grid.cntY,
                distX: grid.distX,
                distY: grid.distY,
                angleX: grid.angleX,
                angleY: grid.angleY,
                alternateMode: grid.alternateMode,
                shift: grid.shift,
                ratioXY: grid.ratioXY,
                keepRatioXY: grid.keepRatioXY
                // widthX, widthY, origin, vecX and vecY are not stored
            },
            numeration: {
                modeInRow: renumberTool.modeInRow,
                modeAlternate: renumberTool.modeAlternate,
                modeRows: renumberTool.modeRows,
                startNumber: renumberTool.startNumber,
                displaySeatNo: renumberTool.displaySeatNo,
                tableModeInRow: renumberTool.tableModeInRow,
                tableModeAlternate: renumberTool.tableModeAlternate,
                tableModeRows: renumberTool.tableModeRows,
                tableStartNumber: renumberTool.tableStartNumber,
                displayTableNo: renumberTool.displayTableNo,
                rowModeDirection: renumberTool.rowModeDirection,
                rowModeText: renumberTool.rowModeText,
                rowStartNumber: renumberTool.rowStartNumber,
                rowTextSize: renumberTool.rowTextSize,
                rowTextDist: renumberTool.rowTextDist,
                displayRowNo: renumberTool.displayRowNo,
                seatsAtTableMode: renumberTool.seatsAtTableMode,
                seatsAtTableStartNumber: renumberTool.seatsAtTableStartNumber,
                displaySeatNoAtTable: renumberTool.displaySeatNoAtTable
            }
        };
        block.setAttribute('$PlacingBlock', info);
    }

    /**
     * retrieve defining parameters from the $PlacingBlock attached to the block root op
     * @param {*} op 
     * @param {*} grid 
     * @param {*} renumberTool 
     */
    static retrieveBlockParameters(block, grid, renumberTool) {

        const info = block.getAttribute('$PlacingBlock');
        if (!info || info.version == 0)
            return;
        // TODO: Change System (all from geomatry(analyseBlockGroup) or $Placingblock)
        // grid.mode = info.grid.mode;
        // grid.cntX = info.grid.cntX;
        // grid.cntY = info.grid.cntY;
        // grid.distX = info.distX;
        // grid.distY = info.distY;
        // grid.angleX = info.angleX;
        // grid.angleY = info.angleY;
        grid.alternateMode = info.grid.alternateMode;
        // grid.shift = info.shift;
        // grid.ratioXY = info.ratioXY;
        // grid.keepRatioXY = info.keepRatioXY;
        // grid.vecX = { x: Math.cos(deg2Rad(info.angleX)), y: Math.sin(deg2Rad(info.angleX)) };
        // grid.vecY = { x: Math.cos(deg2Rad(info.angleY)), y: Math.sin(deg2Rad(info.angleY)) };

        renumberTool.modeInRow = info.numeration.modeInRow;
        renumberTool.modeAlternate = info.numeration.modeAlternate;
        renumberTool.modeRows = info.numeration.modeRows;
        renumberTool.startNumber = info.numeration.startNumber;
        renumberTool.displaySeatNo = info.numeration.displaySeatNo;
        renumberTool.tableModeInRow = info.numeration.tableModeInRow;
        renumberTool.tableModeAlternate = info.numeration.tableModeAlternate;
        renumberTool.tableModeRows = info.numeration.tableModeRows;
        renumberTool.tableStartNumber = info.numeration.tableStartNumber;
        renumberTool.displayTableNo = info.numeration.displayTableNo;
        renumberTool.rowModeDirection = info.numeration.rowModeDirection;
        renumberTool.rowModeText = info.numeration.rowModeText;
        renumberTool.rowStartNumber = info.numeration.rowStartNumber;
        renumberTool.rowTextSize = info.numeration.rowTextSize;
        renumberTool.rowTextDist = info.numeration.rowTextDist;
        renumberTool.displayRowNo = info.numeration.displayRowNo;
        renumberTool.seatsAtTableMode = info.numeration.seatsAtTableMode;
        renumberTool.seatsAtTableStartNumber = info.numeration.seatsAtTableStartNumber;
        renumberTool.displaySeatNoAtTable = info.numeration.displaySeatNoAtTable;

        // // widthX, widthY, origin, vecX and vecY are determined as properties
        // // of the first OpReference in the block
        // const op = getSeat(getRow(block, 0), 0);
        // const opInfo = BlockUtils.getSymbolInfo(op);

        // grid.widthX = opInfo.widthX;
        // grid.widthY = opInfo.widthY;
        // grid.origin = { x: opInfo.x, y: opInfo.y };
    }

    /**
     * check, whether op is in a group structure block -> rows 
     * @param {*} op 
     * @returns group or null
     */
    static findBlockGroup (op) {
        if (op.parent instanceof OpGroup) {
            const row = op.parent;
            if (BlockUtils.isBlockGroup(row.parent))
                return row.parent;
        }
        return null;
    }
    /**
     * collect all blocks, to which at least one of the objects belongs
     * 
     * @param {*} objects 
     * @returns 
     */

    static getBlocks (objects) {

        const blocks = new Set();
        objects.forEach(op => {
            const block = BlockUtils.findBlockGroup(op);
            if(block) {
                if(!blocks.has(block))
                    blocks.add(block);
            } else
                blocks.add(op);
        });

        return Array.from(blocks);
    }

    /**
     * collect all references in the block
     * @param {*} block 
     * @returns 
     */
    static getReferences (block) {
        const references = [];
        for (const row of block.children.filter(op => op instanceof OpGroup)) 
            references.push(...row.children.filter(op => op instanceof OpReference));
        return references;
    }
    
    /**
     * set in 'grid' the defining parameters for starting with a so far isolated symbol op
     * 
     * start with GridMode.SINGLE
     * does not change in grid count and dist as well as pattern settings
     * @param {*} op 
     * @param {*} grid 
     * @returns 
     */
    static analyseSymbol (op, grid) {
        const info = BlockUtils.getSymbolInfo(op);

        grid.mode = GridMode.SINGLE;
        grid.angleX = info.angle;
        grid.angleY = periodic(grid.angleX + 90, 0, 360);
        grid.widthX = info.widthX;
        grid.widthY = info.widthY;

        grid.origin = { x: info.x, y: info.y };
        grid.vecX = { x: Math.cos(deg2Rad(grid.angleX)), y: Math.sin(deg2Rad(grid.angleX)) };
        grid.vecY = { x: Math.cos(deg2Rad(grid.angleY)), y: Math.sin(deg2Rad(grid.angleY)) };
    }

    /**
     * set in 'grid' the defining parameters of a given block
     * TODO: triangular pattern not recognised correctly yet
     * TODO: add recognition of the renumberTool parameters
     * @param {*} block 
     * @param {*} grid 
     * @returns 
     */
    static analyseBlockGroup (block, grid) {
        const op = getSeat(getRow(block, 0), 0);

        const cntY = block.children.length;
        const cntX = getCntX(block);
        
        // if there is only one symbol in the block, just analyse this single symbol
        if (cntX === 1 && cntY === 1) {
            BlockUtils.analyseSymbol(op, grid);
            return;
        }

        if (cntX === 1) {
            // the pattern is a single column
            
            if (cntY === 2)
                // cornercase: two rows with only a single item
                BlockUtils.analyseCornercase(block, grid);
            else
                // single items in each row
                BlockUtils.analyseColumn(block, grid);

        } else {
            // more than one item in the rows
            
            const info = BlockUtils.getSymbolInfo(op);

            grid.mode = GridMode.RECT;
            grid.cntX = Math.max(cntX, 1);
            grid.cntY = Math.max(cntY, 1);
            grid.widthX = info.widthX;
            grid.widthY = info.widthY;

            // look at the first row to determine all parameters for the x-direction
            const op1 = getSeat(getRow(block, 0), 1);
            
            const angleX = BlockUtils.angle(op, op1);
            const offsetX = BlockUtils.dist(op, op1);
            grid.distX = offsetX - grid.widthX;

            // start with assuming the y-direction to be perpendicular to the x-direction
            let angleY = periodic(angleX + 90, 0, 360);
            
            const origin = { x: info.x, y: info.y };
            const vecX = { x: Math.cos(deg2Rad(angleX)), y: Math.sin(deg2Rad(angleX)) };
            const vecY = { x: Math.cos(deg2Rad(angleY)), y: Math.sin(deg2Rad(angleY)) };

            if (cntY > 2) {

                // look at third row
                const op2 = getSeat(getRow(block, 1), 0);
                const op3 = getSeat(getRow(block, 2), 0);
                const info2 = BlockUtils.getSymbolInfo(op2);            
                const info3 = BlockUtils.getSymbolInfo(op3);         
                const c2 = this.coord(origin, vecX, vecY, info2.x, info2.y);
                const c3 = this.coord(origin, vecX, vecY, info3.x, info3.y);

                const offsetY = c3.v / 2;
        
                if (equalsZero(c3.u)) {
                    
                    if (equalsZero(c2.u)) {
                        grid.alternate = false;
                        grid.keepRatioXY = false;
                    } else  { 
                        // shifted every second row
                        grid.alternate = true;
                        // grid.shift = c2.u / offsetX;  shift values other than 0.5 are not really supported
                        grid.keepRatioXY = equals(offsetY, grid.ratioXY * offsetX); // triangular pattern
                    } 
        
                } else {
                    // skewed pattern (presume that skewed and shifted is never allowed)
                    grid.alternate = false;
                    grid.keepRatioXY = false;
                    angleY = BlockUtils.angle(op, op2);
                }

                // no knowledge about distY in triangular pattern
                if (!grid.keepRatioXY)
                    grid.distY = offsetY - grid.widthY;

            } else if (cntY === 2) {

                //only two rows
                const op2 = getSeat(getRow(block, 1), 0);
                const info2 = BlockUtils.getSymbolInfo(op2);            
                const c2 = this.coord(origin, vecX, vecY, info2.x, info2.y);

                const offsetY = c2.v;

                if (equalsZero(c2.u)) {
                    grid.alternate = false;
                    grid.keepRatioXY = false;
                } else if (equals(offsetX, c2.u *2)) { 
                    // shifted every second row
                    grid.alternate = true;
                    // grid.shift = c2.u / offsetX;  shift values other than 0.5 are not really supported
                    grid.keepRatioXY = equals(offsetY, grid.ratioXY * offsetX); // triangular pattern
                } else {
                    // skewed pattern (presume that skewed and shifted is never allowed)
                    grid.alternate = false;
                    grid.keepRatioXY = false;
                    angleY = BlockUtils.angle(op, op2);
                }

                // no knowledge about distY in triangular pattern
                if (!grid.keepRatioXY)
                    grid.distY = offsetY - grid.widthY;

            } else {
                // only one row: no further knowledge about y-direction
                // assume perpendicular
            }
            
            grid.angleX = angleX;
            grid.angleY = angleY;
    
            grid.origin = { x: info.x, y: info.y };
            grid.vecX = { x: Math.cos(deg2Rad(angleX)), y: Math.sin(deg2Rad(angleX)) };
            grid.vecY = { x: Math.cos(deg2Rad(angleY)), y: Math.sin(deg2Rad(angleY)) };
        }
    }

    /**
     * set in 'grid' the defining parameters for the special case
     * 
     * row 2    op2         op2          op2
     *           |    or   |      or     /
     * row 1    op1       op1          op1
     * 
     * It is not possible to distinguish between the second, thus assume the shift 
     * by 0.5.
     * 
     * @param {*} op 
     * @param {*} grid 
     * @returns 
     */
    static analyseCornercase (block, grid) {

        grid.cntX = 1;
        grid.cntY = 2;

        const op1 = getSeat(getRow(block, 0), 0);
        const op2 = getSeat(getRow(block, 1), 0);

        const info1 = BlockUtils.getSymbolInfo(op1);            
        const info2 = BlockUtils.getSymbolInfo(op2);        

        grid.mode = GridMode.RECT;
        grid.angleX = info1.angle;
        grid.angleY = periodic(grid.angleX + 90, 0, 360);
        grid.widthX = info1.widthX;
        grid.widthY = info1.widthY;

        grid.origin = { x: info1.x, y: info1.y };
        grid.vecX = { x: Math.cos(deg2Rad(grid.angleX)), y: Math.sin(deg2Rad(grid.angleX)) };
        grid.vecY = { x: Math.cos(deg2Rad(grid.angleY)), y: Math.sin(deg2Rad(grid.angleY)) };

        grid.keepRatioXY = false;

        const c = this.coord(grid.origin, grid.vecX, grid.vecY, info2.x, info2.y);

        const offsetY = c.v;
        grid.distY = offsetY - grid.widthY;

        if (equals(c.u, 0)) {
            grid.alternate = false;
            // no knowledge about distX
        } else {
            grid.alternate = true;
            grid.shift = 0.5;
            const offsetX = c.u * 2;
            grid.distX = offsetX - grid.widthX;
            grid.keepRatioXY = equals(offsetY, grid.ratioXY * offsetX); // triangular
        }
        
        // no knowledge about distY in triangular pattern
        if (!grid.keepRatioXY)
            grid.distY = offsetY - grid.widthY;
    }

    /**
     * set in 'grid' the defining parameters for a single column with at least 3 rows
     * 
     * row 3    op3        op3             op3
     *           |         |               /
     * row 2    op2         op2          op2
     *           |    or   |      or     /
     * row 1    op1       op1          op1
     * 
     * @param {*} op 
     * @param {*} grid 
     * @returns 
     */
    static analyseColumn (block, grid) {

        grid.cntX = 1;
        grid.cntY = Math.max(block.children.length, 1);

        const op1 = getSeat(getRow(block, 0), 0);
        const op2 = getSeat(getRow(block, 1), 0);
        const op3 = getSeat(getRow(block, 2), 0);

        const info1 = BlockUtils.getSymbolInfo(op1);            
        const info2 = BlockUtils.getSymbolInfo(op2);            
        const info3 = BlockUtils.getSymbolInfo(op3);         

        grid.mode = GridMode.RECT;
        grid.angleX = info1.angle;
        grid.angleY = periodic(grid.angleX + 90, 0, 360);
        grid.widthX = info1.widthX;
        grid.widthY = info1.widthY;

        grid.origin = { x: info1.x, y: info1.y };
        grid.vecX = { x: Math.cos(deg2Rad(grid.angleX)), y: Math.sin(deg2Rad(grid.angleX)) };
        grid.vecY = { x: Math.cos(deg2Rad(grid.angleY)), y: Math.sin(deg2Rad(grid.angleY)) };

        const c2 = this.coord(grid.origin, grid.vecX, grid.vecY, info2.x, info2.y);
        const c3 = this.coord(grid.origin, grid.vecX, grid.vecY, info3.x, info3.y);

        const offsetY = c3.v / 2;

        if (equalsZero(c3.u)) {
            
            if (equalsZero(c2.u)) {
                grid.alternate = false;
                grid.keepRatioXY = false;
                // no knowledge about distX
            } else {
                grid.alternate = true;
                grid.shift = 0.5;
                const offsetX = c2.u * 2;
                grid.distX = offsetX - grid.widthX;
                grid.keepRatioXY = equals(offsetY, grid.ratioXY * offsetX); // triangular
            }

        } else {
            grid.alternate = false;
            grid.keepRatioXY = false;
            grid.angleY = BlockUtils.angle(op1, op2);
            // no knowledge about distX
        }

        // no knowledge about distY in triangular pattern
        if (!grid.keepRatioXY)
            grid.distY = offsetY - grid.widthY;
   }

    static dist(op1, op2) {
        const info1 = BlockUtils.getSymbolInfo(op1);
        const info2 = BlockUtils.getSymbolInfo(op2);
        return Math.sqrt((info2.x - info1.x) * (info2.x - info1.x) + (info2.y - info1.y) * (info2.y - info1.y));
    }

    static coord(origin, vecX, vecY, x, y) {

        const b = new Matrix3();
        b.set( vecX.x, vecY.x, origin.x,
               vecX.y, vecY.y, origin.y,
               0, 0, 1);

        b.invert();
        const vec = new Vector3(x, y, 1);
        vec.applyMatrix3(b);

        return { u: vec.x,  v: vec.y };
    }

    static angle(op1, op2) {
        const info1 = BlockUtils.getSymbolInfo(op1);
        const info2 = BlockUtils.getSymbolInfo(op2);
        const dX = info2.x - info1.x;
        const dY = info2.y - info1.y;
        return rad2Deg(Math.atan2(dY, dX));
    }

    static getSymbolInfo (op) {
        const t = op.transform;
        const p = Geometry.getTranslation(t);
        const angle = Geometry.getRotationAngle(t);
        const sx = Geometry.getScaleX(t);
        
        const box = op.computeBox();
        const widthX = box.max.x - box.min.x;
        const widthY = box.max.y - box.min.y;

        return { x: p[0], y :p[1], angle: angle, scale: sx, widthX : widthX, widthY: widthY };
    }

    /**
     * take over the block into a PlaceSymbolList
     * - block root
     * - groups
     * - symbols
     * - prototype as copy of one of the symbols
     * @param {*} block 
     * @param {*} placeSymbolList 
     */
    static fillPlaceSymbolList(block, placeSymbolList) {
        placeSymbolList.block = block;
        placeSymbolList.prototype = getSeat(getRow(block, 0), 0).copy(); 

        block.children.forEach(op => { 
            if (op instanceof OpGroup) {
                const row = op;
                placeSymbolList.groups.push(op);
                row.children.forEach(op => {
                    if (op instanceof OpReference)
                    placeSymbolList.symbols.push(op);
                });
             }
        });
    }

    /**
     * calculate an axis-parallel box which covers the grid pattern
     * @param {*} prototype 
     * @param {*} grid 
     * @returns 
     */
    static computeSelectBox(prototype, grid) {
        const box = prototype.computeBox();
        const offsetX = grid.distX + grid.widthX;
        const offsetY = grid.distY + grid.widthY;
        let lengthX = (grid.getCntX() - 1) * offsetX + grid.widthX; 
        let lengthY = (grid.getCntY() - 1) * offsetY + grid.widthY;

        if ((grid.alternateMode === AlternateMode.RIGHT || grid.alternateMode === AlternateMode.SHIFT_TRIANGLE) && grid.mode != GridMode.SINGLE && grid.cntY > 1)
            lengthX += offsetX * grid.shift;
        else if ((grid.alternateMode === AlternateMode.LEFT) && grid.mode != GridMode.SINGLE && grid.cntY > 1) {
            box.min.sub(new Vector3(offsetX * grid.shift, 0, 0));
            lengthX += offsetX * grid.shift;
        }
            
        if (grid.keepRatioXY)
            lengthY = (grid.getCntY() - 1) * grid.ratioXY * offsetX + grid.widthY;

        const v = new Vector3(lengthX, lengthY, 0);

        box.max.copy(box.min).add(v)

        return box;
    }

    /**
     * set Matrix4 t to the local coordinate system of the block, i.e. anchored in the
     * grid.origin and x-axis in grid.angleX
     * @param {*} t 
     * @param {*} grid 
     */
    static makeGridTransform(t, grid) {
        Geometry.makePlacingTransform(t, grid.origin.x, grid.origin.y, 0, grid.angleX);
    }
}