<template>
    <div class="side-pane scroll" :class="classWidth" v-show="currentPanel">
        <component :is="currentPanel"></component>
    </div>
</template>

<script>
import theApp from '@/frame/Application';

import DlgPlaceBlock from '@/vuejs/components/DlgPlaceBlock.vue';
import SymbolLibraryPanel from '@/vuejs/components/SymbolLibraryPanel.vue';
import PaneSimpleFavorites from '@/vuejs/components/PaneSimpleFavorites.vue';
import PanelAttributes from '@/vuejs/components/PanelAttributes.vue';
import SettingsPanel from '@/vuejs/components/SettingsPanel.vue';
import PanelFurniture from '@/vuejs/components/PanelFurniture.vue';
import PanelDraw from '@/vuejs/components/PanelDraw.vue';
import DlgInventory from '@/vuejs/components/DlgInventory.vue';
import SunSky from '@/vuejs/components/SunSky.vue';
import DlgFloorplan from '@/vuejs/components/DlgFloorplan.vue';

export default {
    name: 'SidePane',
    components: {
        DlgPlaceBlock,
        PanelFurniture,
        PanelDraw,
        SymbolLibraryPanel,
        PaneSimpleFavorites,
        PanelAttributes,
        SettingsPanel,
        DlgInventory,
        SunSky,
        DlgFloorplan
    },
    data () {
        return {
            currentPanel: undefined,
            classWidth: ['col-lg-3 col-md-4 col-sm-6']
        }
    },
    mounted () {
        theApp.addDialog('SidePane', this)
    },
    unmounted () {
        theApp.removeDialog('SidePane')
    },
    methods: {
        setCurrentPanel(key) {
            this.currentPanel = key
        },
        /**
         * override the default width of the side panel, e.g.
         * sidePane.setClassWidth('ve-width-full')   ->   use full container width
         * 
         * sidePane.setClassWidth('ve-width-full-sidenav')   ->   use full container width - space for the side navigation
         * (avoids horizontal scroll bar)
         */
        setClassWidth (classWidth) {
            this.classWidth = classWidth
        },
        /**
         * reset the default width of the side panel
         */
        resetClassWidth () {
            this.classWidth = ['col-lg-3 col-md-4 col-sm-6']
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/ve-variables.scss';
.side-pane {
    background: white;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    left: $sidenav-button-width;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5)
}

.ve-width-full {
    width: 100%;
}

.ve-width-full-sidenav {
    width: calc(100% - $sidenav-button-width);
}
</style>