import Command from '@/frame/Command';
import CommandPool from '@/frame/CommandPool';
import theApp from '@/frame/Application';
import Action from '@/frame/Action';

import ActDoor from '@/visual-events/actions/ActDoor';
import ActWindow from '@/visual-events/actions/ActWindow';
import ActRectangularWalls from '@/visual-events/actions/ActRectangularWalls';
import ActWaitForUserInput from '@/visual-events/actions/ActWaitForUserInput';

import Logger from '@/frame/Logger';
import Settings from './data/Settings';

const logger = new Logger('CommandPoolFloorplan');

/**
 * this command pool contains commands for drawing building objects, such as
 * - walls
 * - openings, i.e. doors and windows
 * 
 */
export default class CommandPoolFloorplan extends CommandPool {
    constructor() {
        super(CommandPool.root, 'floorplan');
        this.addCommands([
            new Command('openDialog', args => this.doOpenDialog(args)),
            new Command('rectangularWall', args => this.doRectangularWall(args)),
            new Command('editFloorPlan', args => this.doEditFloorPlan(args)),
            new Command('placeDoor', args => this.doPlaceDoor(args)),
            new Command('placeWindow', args => this.doPlaceWindow(args)),
            new Command('editDoor', args => this.doEditDoor(args)),
            new Command('editWindow', args => this.doEditWindow(args))
        ]);
    }

    doOpenDialog (args) {
        logger.log('doOpenDialog');
        if (!Action.findByClass(ActWaitForUserInput))
            Action.start(new ActWaitForUserInput());
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Floorplan');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('DlgFloorplan');
    }

    doRectangularWall (args) {
        logger.log('doRectangularWall');
        if (Settings.get('floorplan.enabled'))
            Action.start(new ActRectangularWalls(args));
    }

    doEditFloorPlan (args) {
        logger.log('doEditFloorPlan');
         //TODO: insufficient as soon as there are more than rectangular floor plans
        if (Settings.get('floorplan.enabled'))
            Action.start(new ActRectangularWalls(args));
    }

    doPlaceDoor (args) {
        logger.log('doPlaceDoor');
        if (Settings.get('floorplan.enabled'))
            Action.start(new ActDoor(args));
    }

    doPlaceWindow (args) {
        logger.log('doPlaceWindow');
        if (Settings.get('floorplan.enabled'))
            Action.start(new ActWindow(args));
    }

    doEditDoor (args) {
        logger.log('doEditDoor');
        if (Settings.get('floorplan.enabled'))
            Action.start(new ActDoor(args));
    }

    doEditWindow (args) {
        logger.log('doEditWindow');
        if (Settings.get('floorplan.enabled'))
            Action.start(new ActWindow(args));
    }
}