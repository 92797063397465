import * as THREE from 'three'; //TODO:  import tree in Inventory raus

import Settings from '@/visual-events/data/Settings';

import RestAPI from '@/visual-events/RestAPI/RestAPI';
import FileService from '@/visual-events/RestAPI/FileService';
import CADdy2DLoader from '@/visual-events/loader/CADdy2DLoader';
import CADdyGLTFLoader from '@/visual-events/loader/CADdyGLTFLoader';
import { CADdyType } from '@/visual-events/loader/CADdyType'
import OpFactory from '@/visual-events/model/OpFactory';
import OpSymbol from '@/visual-events/model/OpSymbol';
import Logger from '@/frame/Logger';

const logger = new Logger('Inventory');

/**
 *  utility class for working with inventory
 * 
 * ------------------------------------------------------------------------------------------
 * 
 *  working with filters and conditions
 * 
 *  The dialog DlgInventory works with two different buttons for filtering inventory.
 * 
 * - a filter is the tag with the list of options which can be selected
 *   (lateron there will be other kind of filters for defining a range of values [min, max])
 * 
 *      {
 *          tag: 'furnitureType',
 *          name: 'Möbelart', 
 *          options: [ 
 *              { value: 'Stuhl', checked: true }, 
 *              { value: 'Tisch', checked: false }
 *          ]
 *      }
 * 
 * - a condition is a single where clause such as
 * 
 *      {
 *          tag: 'furnitureType',
 *          value: 'Stuhl'    
 *      }
 * 
 * ------------------------------------------------------------------------------------------
 *
 *  applying filters 
 *  
 *  Filtered items are displayed in cards.
 * 
 * ------------------------------------------------------------------------------------------
 *  
 *  loading the 2D and 3D files
 * 
 * ------------------------------------------------------------------------------------------
 * 
 *  wrappers for the Inventory REST api
 * 
 */
export default class Inventory {

//----------------------------------------------------------------------------------------------------
// cards for the filtered items
//----------------------------------------------------------------------------------------------------

    /**
     * query items and return an array of filter items
     * 
     * Each item is described as json data as needed to show the cards in DlgInventory
     * 
     * example:
     * {
     *   id: 4,
     *   src:  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==', //thumbnail src
     *   title: 'Tisch XY',
     *   text: 'toller Tisch',
     *   furnitureType: 'Tisch'
     * }
     * 
     * In the UI, pagination counts like 1 .. maxPage.
     * 
     * @param {*} query 
     * @returns { total: result.total, items: items }
     */
    static async getFilteredItems (query, searchTerm, limit=5, page=1, images=true) {

        const opts = [];

        for (const condition of query)
            opts.push([ 'tags',  `${condition.tag},${condition.value}` ]);
        if (searchTerm)
            opts.push([ 'searchTerm', searchTerm ]);
        if (limit)
            opts.push([ 'limit', limit])
        if (page)
            opts.push([ 'page', page])
        if (images)
            opts.push([ 'images', images])

        const result = await Inventory.getInventoryItems(opts);

        logger.log(`${JSON.stringify(result)}`)

        const items = result.items.map(item => {
            const cardData = {
                id: item._id,
                src: `data:image/jpeg;base64,${item.images[0]}`,
                title: item.name,
                text: `${item.tags['furnitureType']}`
            }
            return cardData;
        });
        return { total: result.total, items: items };
    }

    /**
     * retrieve the available tags in the inventory database together with all occurring values
     * and attach a boolean variable checked to each value.
     * 
     * example:
     *  {
     *     _id: 'furnitureType',
     *     values: [ 'Stuhl', 'Tisch'],
     *     metadata: { displayName: 'Möbelart' }
     *  }
     * 
     * By attaching a boolean variable 'checked' to each value, the methode provides the data structure 
     * as needed in DlgInventory in order to define the filtering options.
     * 
     * example:
     *  {
     *       tag: 'furnitureType',
     *       name: 'Möbelart',  // taken from metadata
     *       options: [ 
     *           { value: 'Stuhl', checked: false }, 
     *           { value: 'Tisch', checked: false }
     *       ]
     *   },
     * 
     * @returns 
     */
    static async getFilters () {
        let result = await Inventory.getTags();

        const tags = result.items;

        const filters = Object.entries(tags).map(([key, tag]) => {
            const filter = {
                tag: key,
                name: tag.metadata.displayName,
                options: []
            }
            for (const value of tag.values) 
                filter.options.push( { value: value, checked: false });

            return filter;
        });

        return filters;
    }

//----------------------------------------------------------------------------------------------------
//  helpers to work with filters and conditions
//----------------------------------------------------------------------------------------------------
    
    static findFilter(filters, tag) {
        return filters.find(filter => filter.tag === tag);
    }

    static findOptionInFilter(filter, value) {
        return filter.options.find(option => option.value === value);
    }

    static findCondition(conditions, tag, value) {
        return conditions.find(condition => condition.tag === tag && condition.value === value);
    }

    static addConditionToConditions(conditions, tag, value) {
        conditions.push( {
            tag: tag,
            value: value
        })
    }

    static removeConditionFromFilters(filters, condition) {
        const filter = Inventory.findFilter(filters, condition.tag);
        const option = Inventory.findOptionInFilter(filter, condition.value);
        option.checked = false;
    }

    static addConditionToFilters(filters, condition) {
        const filter = Inventory.findFilter(filters, condition.tag);
        const option = Inventory.findOptionInFilter(filter, condition.value);
        option.checked = true;
    }

    static removeConditionFromConditions(conditions, condition) {
        conditions.splice(conditions.indexOf(condition), 1);
    }

    /**
     * build the list of query conditions { tag, value } for all checked filter values
     */
    static extractConditionsFromFilters (filters) {
        const conditions = [];

        filters.forEach(filter => {
            filter.options.forEach(option => {
                if (option.checked) 
                    conditions.push( { tag: filter.tag, value: option.value });
                })
        })

        return conditions;  
    }

    static addConditionsToFilters (filters, conditions) {
        conditions.forEach(condition =>   Inventory.addConditionToFilters(filters, condition));
    }

    static storeConditionsInSettings(conditions) {
        Settings.set('inventory.filterConditions', conditions);
    }

    static restoreConditionsFromSettings(filters) {
        Inventory.addConditionsToFilters(filters, Settings.get('inventory.filterConditions'));
    }

//----------------------------------------------------------------------------------------------------
//  loading 2D and 3D symbols
//----------------------------------------------------------------------------------------------------

    /**
     * load the 2D file (svg according to CADdy++ model structure) into the symbols area
     * @param {*} itemId 
     * @returns null, if failed, OpSymbol otherwise
     */
    static async createSymbol2D (itemId) {

        logger.log (`createSymbol2D(${itemId})`);

        const item = await Inventory.getInventoryItem(itemId);
        logger.log (`found item ${item.name}`);

        const svg = item['2d']?.find(file => file.endsWith('.svg'));
        if (!svg)
            return null; //

        const urlSVG = Settings.get('.urlFileService') + "/files/" + svg

        const loader = new CADdy2DLoader();
        loader.requestHeader = FileService.requestHeader();
        const opObjects = await loader.loadAsync(urlSVG);

        const symbols = opObjects.filter(op => op instanceof OpSymbol);

        if (symbols.length < 1)
            return null;

        return symbols[0];
    }

    /**
     * load the 3D file (gltf according to CADdy++ web export model structure) into the symbols area
     * @param {*} itemId 
     * @returns null, if failed, OpSymbol otherwise
     */
    static async createSymbol3D (itemId) {

        logger.log (`createSymbol3D(${itemId})`);

        const item = await Inventory.getInventoryItem(itemId);
        logger.log (`found item ${item.name}`);

        const gltf = item['3d']?.find(file => file.endsWith('.gltf'));
        if (!gltf)
            return null; 

        const urlGLTF = Settings.get('.urlFileService') + "/files"

        const loader = new CADdyGLTFLoader();
        loader.requestHeader = FileService.requestHeader();
        const mesh = await loader.loadAsync(urlGLTF, gltf, new THREE.Matrix4());
        const meshUrl = `${urlGLTF}/${gltf}`;

        const op = OpFactory.createMesh(CADdyType.SCENE_ITEM, '3D Symbol', mesh, meshUrl);

        const symbol = OpFactory.createSymbol(item.name);
        symbol.add(op);

        //HACK: store chair as standard in ActChairXXX
        const tag = item.tags['furnitureType']
        if (tag && tag.includes('Stuhl')) {
            Inventory.chair = symbol;
        }

        return symbol;
    }

    static chair = null;

//----------------------------------------------------------------------------------------------------
// REST Api wrappers
//----------------------------------------------------------------------------------------------------

    /**
     * retrieve the available tags 
     * 
     * &expand calls for the options, i.e. the values which occur with respect to each tag
     * @param {*} opts 
     * @returns 
     */
    static async getTags(opts) {

        const query = `?${new URLSearchParams(opts).toString()}`;
    
        const endpoint = `${Settings.get('.urlInventory')}/inventory/tags${query}&expand=true`;
        const method = 'GET';
        const header = new Headers({Accept: 'application/json', 'VisualEvents-ApiKey': Settings.get('.userToken'), 'VisualEvents-Language': Settings.get('.language')});
    
        try {
            const data = await RestAPI.getResponse(endpoint, method, header);
            return data;
        } catch(e) {
            console.log(e)
            logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
        }
    
        return null;
    }

    /**
     * start a query on inventory items with search criteria about tags to filter in 'opts'
     *
     *  example:
     * 
     * opts = [ [ tags, 'furnitureType,Tisch' ], [ tags, 'color,white' ] ]
     * 
     *  ?tags=furnitureType,Stuhl&tags=furnitureType,Tisch&tags=color,white
     * 
     * In order to demand for metadata, use  [ 'expand', true ]  
     * 
     * ... &expand=true
     * 
     * For pagination add limit and page, e.g. [ [ 'limit', 25 ], [ 'page', 1 ]]
     * (Pages count like 1 .. maxPage!)
     * 
     * ... &limit=25&page=1
     * 
     * @param {*} opts 
     * @returns 
     */
    static async getInventoryItems(opts) {

        const query = `?${new URLSearchParams(opts).toString()}`;
   
        const endpoint = `${Settings.get('.urlInventory')}/inventory${query}`;
        const method = 'GET';
        const header = new Headers({Accept: 'application/json', 'VisualEvents-ApiKey': Settings.get('.userToken'), 'VisualEvents-Language': Settings.get('.language')});
    
        try {
            const data = await RestAPI.getResponse(endpoint, method, header);
            return data;
        } catch(e) {
            logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
        }
    
        return null;
    }

    /**
     * retrieve the item with 'id'
     * 
     * @param {*} id 
     * @returns 
     */
    static async getInventoryItem(id) {

        const endpoint = `${Settings.get('.urlInventory')}/inventory/${id}`;
        const method = 'GET';
        const header = new Headers({Accept: 'application/json', 'VisualEvents-ApiKey': Settings.get('.userToken'), 'VisualEvents-Language': Settings.get('.language')});
    
        try {
            const data = await RestAPI.getResponse(endpoint, method, header);
            return data;
        } catch(e) {
            logger.error(`Exception:${method} ${endpoint}:\n${e.message}`);
        }
    
        return null;
    }

    /**
     * 
     * @param {*} url 
     */
    static async getGeometryFile(url) {
        FileService.get(url);        
    }
}