<template>
    <div class="panel-style p-3">
        <fieldset class="ve-row">
            <div>
              <InputCheckBox class="col-4" label=Linie :min="1" v-model="useStroke" @change="onChangeUseStroke" />
              <InputButtonColor class="col-4" label="" v-model="stroke"  @update:modelValue="onChangeStroke" />
              <InputNumberWithUnit class="col-6" unit='px' label="Stärke" :min=1 :max=100 v-model="strokeWidth" @update:modelValue="onChangeStrokeWidth" />
            </div>
            <div class="mt-2" v-if="showFillStyle === true">
              <InputCheckBox class="col-4" label=Füllung v-model="useFill" @change="onChangeUseFill" />
              <InputButtonColor class="col-6" label="" v-model="fill"  @update:modelValue="onChangeFill" />
            </div>
        </fieldset>
    </div>
</template>

<script>

// Anmerkung: bei den CheckBoxen wird auf das Event"@change" reagiert statt auf "@update:modelValue"
//  Der Grund ist, das bei Benutzung von "@update:modelValue" die CheckBox nicht richtig 
//  angezeigt wird, wenn ohr Zustand von der Callback-Funktion wieder zurückgesetzt wird

import theApp from '@/frame/Application';
import Settings from '@/visual-events/data/Settings';
import InputButtonColor from '@/vuejs/components/InputButtonColor.vue';
import InputNumberWithUnit from '@/vuejs/components/InputNumberWithUnit.vue';
import InputCheckBox from '@/vuejs/components/InputCheckBox.vue';

export default {
  name: 'PanelStyle',
  components: {
    InputButtonColor,
    InputNumberWithUnit,
    InputCheckBox
  },
  data () {
    return {
        useStroke: Settings.get('style.useStroke'),
        stroke: Settings.get('style.stroke'),
        strokeWidth : Settings.get('style.strokeWidth'),
        useFill: Settings.get('style.useFill'),
        fill : Settings.get('style.fill'),
        showFillStyle : true,
    }
  },
  mounted () {
    theApp.addDialog('PanelStyle', this)
  },
  unmounted () {
    theApp.removeDialog('PanelStyle')
  },
  methods: {
    update(action) {
        this.useStroke = action.useStroke
        // InputButtonColor requires #rrggbb
        this.stroke = action.stroke === 'none' ? this.stroke : action.stroke;
        this.strokeWidth = action.strokeWidth
        this.useFill = action.useFill
        // InputButtonColor requires #rrggbb
        this.fill = action.fill === 'none' ? this.fill : action.fill;
    },    
    setShowFillStyle (show) {
      this.showFillStyle = show;
      if (!this.showFillStyle)
      {
        this.useFill = false;
        this.useStroke = true;
        theApp.executeValueEvent("useStroke", this.useStroke)
        theApp.executeValueEvent("useFill", this.useFill)
        Settings.set('style.useStroke', this.useStroke)
        Settings.set('style.useFill', this.useFill)
      }
    },
    onChangeUseStroke () {
        if (!this.useFill && !this.useStroke)
            this.useStroke = true;

        theApp.executeValueEvent("useStroke", this.useStroke)
        if (this.useStroke)
          theApp.executeValueEvent("stroke", this.stroke)
        Settings.set('style.useStroke', this.useStroke)
    },
    onChangeStroke () {
        theApp.executeValueEvent("stroke", this.stroke)
        Settings.set('style.stroke', this.stroke)
    },
    onChangeStrokeWidth () {
        theApp.executeValueEvent("strokeWidth", this.strokeWidth)
        Settings.set('style.strokeWidth', this.strokeWidth)
    },
    onChangeUseFill () {
        if (!this.useFill && !this.useStroke)
        {
            this.useStroke = true;
            theApp.executeValueEvent("useStroke", this.useStroke)
            theApp.executeValueEvent("stroke", this.stroke)
            Settings.set('style.useStroke', this.useStroke)
        }
        theApp.executeValueEvent("useFill", this.useFill)
        if (this.useFill)
          theApp.executeValueEvent("fill", this.fill)
        Settings.set('style.useFill', this.useFill)
    },
    onChangeFill () {
        theApp.executeValueEvent("fill", this.fill)
        Settings.set('style.fill', this.fill)
    }
  }
}
</script>

<style scoped>
</style>
