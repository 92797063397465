<template>
    <div class="dlg-inventory">
        <div class="d-flex flex-column h-100">
            <div class="ve-row">
                <div class="col-12">
                    <div class="ve-panel-header">
                      <span>Inventar Bibliotheken</span>
                    </div>
                </div>
            </div>
            <div class="ve-row">
              <div class="col-12">
                <InputSearchTerm class="m-2" placeholder="Suche..." v-model="searchTerm" @update:modelValue="onChangeSearchTerm"></InputSearchTerm>
              </div>
            </div>
            <div class="ve-row">
              <div class="col-12">
                <div class="query-pane d-flex flex-row m-2 gap-2">
                  <!-- <span class="display-8">ihre Auswahl</span> -->
                  <button 
                      v-for="(condition, index) of conditions" 
                      :key="index" 
                      style="font-size: 12px; padding: 0.25rem;"
                      class="btn ve-btn-outline rounded-pill"
                      @click="onClickQuery(condition)"
                  >
                      {{condition.value}}
                      <i class="fa fa-times m-1" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between m-2" style="padding: 0 0.25rem 0 0.25rem;">
              <div id="btnFilter" class="filter-closed" role="button" @click="toggleFilterPane()"><i class="fa-solid fa-chevron-right"></i></div>
              <div id="txtTotal" class="fs-6 fw-bold">{{ total }} Ergebnisse</div>
              <div id="btnShowMore" class="show-closed" role="button" @click="toggleShowMore()"><i class="fa-solid fa-chevron-right"></i></div>
            </div>
            <div class="d-flex flex-row overflow-auto">
              <div v-if="loading" class="ve-filter-block"></div>
              <div class="accordion col-5 m-2 overflow-auto d-none" id="accordionFilter">
                <div v-for="(filter, index) of filters" :key="filter.tag" class="accordion">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index">
                      {{ filter.name }}
                    </button>
                  </h2>
                  <div :id="'collapse' + index" class="accorion-collapse collapse">
                    <div class="accordion-body">
                      <li class="ve-list" v-for="option of filter.options" :key="option.name" >
                        <InputCheckBox :label="option.value" v-model="option.checked" @update:modelValue="onChangeFilter(filter.tag, option.value)"/>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="loading" class="ve-overlay-static d-flex flex-column align-items-center justify-content-center mt-2">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span class="fs-5 fw-medium mt-2">Daten werden geladen...</span>
              </div>
              <div class="result-pane-grid ve-grid-2 m-2 overflow-auto flex-grow-1" id="resultPane">
                <div v-for="item of items" :key="item.id" class="card ve-inventory-card" @mousedown="onClickItem(item.id)">
                  <i class="fa-solid fa-star m-1 align-self-end" aria-hidden="true"></i>
                    <img :src="item.src" height="75" width="75">
                    <div class="card-body">
                        <span class="card-title ve-card-title">{{item.title}}</span>
                    </div>
                </div>
                <div id="paginationElement" style="min-height: 1px;"></div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import theApp from '@/frame/Application'
import Inventory from '@/visual-events/inventory/Inventory'
// import Pagination from '@/vuejs/components/Pagination';
import InputCheckBox from '@/vuejs/components/InputCheckBox.vue';
import InputSearchTerm from '@/vuejs/components/InputSearchTerm.vue';
import Logger from '@/frame/Logger';

const logger = new Logger('DlgInventory');

export default {
  name: 'DlgInventory',
  components: {
    // Pagination,
    InputCheckBox,
    InputSearchTerm
  },
  data () {
    return {
        loading: false,
        searchTerm: '',
        filters: [],
        conditions: [],
        items: [],
        limit: 25,
        total: 0,
        page: 1  // 1 ... maxPage
    }
  },
  computed: {
    maxPage: {
        get ()  {
           return (this.total > 0) ? Math.trunc ((this.total - 1) / this.limit) + 1 : 0
        }
    }
  },

  mounted () {
    theApp.addDialog('DlgInventory', this)

    //for full width uncomment:
    //const sidePane = theApp.findDialogByName('SidePane');
    //sidePane.setClassWidth('ve-width-full');

    this.filters = this.getFiltersAndSearch().then(() => {

      let isIntersecting = false;

      let updateItemList = async () => {
        while (isIntersecting && this.total > 0 && this.maxPage > 1 && this.page !== this.maxPage) {
          this.page++;
          const result = await Inventory.getFilteredItems(this.conditions, this.searchTerm, this.limit, this.page);
          this.items = this.items.concat(result.items);
        }
      }

      let observer = new IntersectionObserver(async (entries) => {
        let entry = entries[0];
        if (entry.isIntersecting) {
          isIntersecting = true;
          await updateItemList();
        } else {
          isIntersecting = false;
        }
      });
      observer.observe(document.getElementById('paginationElement'));

    });
  },
  unmounted () {
    theApp.removeDialog('DlgInventory')

    const sidePane = theApp.findDialogByName('SidePane');
    sidePane.resetClassWidth();
  },
  methods: {
    toggleFilterPane() {
      let btnFilter = document.querySelector('#btnFilter');
      let btnFilterIcon = btnFilter.children[0];
      let filterPane = document.querySelector('#accordionFilter');
      if (filterPane.classList.contains('d-none')) {
        btnFilter.classList.remove('filter-closed');
        btnFilter.classList.add('filter-open');
        filterPane.classList.remove('d-none');
        btnFilterIcon.classList.remove('fa-chevron-right');
        btnFilterIcon.classList.add('fa-chevron-left');
      } else {
        btnFilter.classList.remove('filter-open');
        btnFilter.classList.add('filter-closed');
        filterPane.classList.add('d-none');
        btnFilterIcon.classList.remove('fa-chevron-left');
        btnFilterIcon.classList.add('fa-chevron-right');
      }
      this.adaptGridSize();
    },
    toggleShowMore() {
      let btnShowMore = document.querySelector('#btnShowMore');
      let btnShowMoreIcon = btnShowMore.children[0];
      btnShowMore.classList.toggle('show-closed');
      btnShowMore.classList.toggle('show-open');
      btnShowMoreIcon.classList.toggle('fa-chevron-right');
      btnShowMoreIcon.classList.toggle('fa-chevron-left');

      let accordionFilter = document.querySelector('#accordionFilter');
      let sidePane = theApp.findDialogByName('SidePane');

      if (btnShowMore.classList.contains('show-open')) {
        sidePane.setClassWidth(['col-5']);
        accordionFilter.classList.toggle('col-5');
        accordionFilter.classList.toggle('col-3');
      } else {
        sidePane.resetClassWidth();
        accordionFilter.classList.toggle('col-3');
        accordionFilter.classList.toggle('col-5');
      }

      this.adaptGridSize();
    },
    adaptGridSize() {
      let resultPane = document.querySelector('#resultPane');

      let btnShowMore = document.querySelector('#btnShowMore');
      let filterPane = document.querySelector('#accordionFilter');

      let gridSize = 1;

      if (btnShowMore.classList.contains('show-open') && filterPane.classList.contains('d-none')) {
        gridSize = 4;
      } else if (btnShowMore.classList.contains('show-open') && !filterPane.classList.contains('d-none')) {
        gridSize = 3;
      } else if (!btnShowMore.classList.contains('show-open') && filterPane.classList.contains('d-none')) {
        gridSize = 2;
      }

      resultPane.classList.remove('ve-grid-1', 've-grid-2', 've-grid-3', 've-grid-4');
      resultPane.classList.add(`ve-grid-${gridSize}`);      
    },
    onClickItem (id) {
        //TODO: execute placeInventory commmand with opts, not with string in commandline
        theApp.executeCommand(`.Ticketing.placeInventory ${id}`)
    },
    onChangePage () {
        this.applyQuery()
    },
    /**
     * on checking or unchecking a filter value add or remove the query condition
     */
    onChangeFilter(tag, value) {
        const filter = Inventory.findFilter(this.filters, tag)
        if (Inventory.findOptionInFilter(filter, value).checked) {
            Inventory.addConditionToConditions(this.conditions, tag, value)
        } else {
            const condition = Inventory.findCondition(this.conditions, tag, value)
            Inventory.removeConditionFromConditions(this.conditions, condition)
        }
        this.applyQuery()
        Inventory.storeConditionsInSettings(this.conditions)
    },
    onChangeSearchTerm() {
      this.applyQuery();
    },
    /**
     * remove the query condition and uncheck the value in the filter
     */
    onClickQuery(condition) {
        Inventory.removeConditionFromFilters(this.filters, condition)
        Inventory.removeConditionFromConditions(this.conditions, condition)
        this.applyQuery()
        Inventory.storeConditionsInSettings(this.conditions)
    },
    /**
     * When entering the dialog, extract all checked options in the filters.
     * Start the search only, if there are already conditions in the query,
     * otherwise wait for the user to add filter conditions.
     */
    async getFiltersAndSearch () {
        this.loading = true;
        this.filters = await Inventory.getFilters()
        Inventory.restoreConditionsFromSettings(this.filters)
        this.conditions = Inventory.extractConditionsFromFilters(this.filters)
        await this.applyQuery()
        this.loading = false;
    },
    async applyQuery () {
        this.loading = true;
        this.page = 1;
        // if (this.conditions.length > 0) {
          try {
            this.items = [];
            this.total = 0;
            const result = await Inventory.getFilteredItems(this.conditions, this.searchTerm, this.limit, this.page)
            this.items = result.items
            this.total = result.total
          } catch (err) {
            logger.error(JSON.stringify(err));
          } finally {
            this.loading = false;
          }

        // } else {
        //     this.items = []
        //     this.total = 0
        // }
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/ve-variables.scss';
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $ve-primary; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lighten($ve-primary, 5%); 
}

.dlg-inventory {
    height: 100%;
    width: 100%;
    background: white;
}

.query-pane {
  flex-wrap: wrap;
  white-space: nowrap;
}

.result-pane-grid {
    display: grid;
    gap: 5px;
    scroll-behavior: smooth;
    grid-auto-rows: max-content;
}

.ve-grid-1 {
  grid-template-columns: 1fr;
}

.ve-grid-2 {
  grid-template-columns: 1fr 1fr;
}

.ve-grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.ve-grid-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.ve-inventory-card {
    flex-grow: 1;
    flex-basis: 40%;
    // align-self: flex-start;
    // white-space: nowrap;
    align-items: center;
    justify-content: center;
}

.ve-inventory-card:hover {
    box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.3);
    cursor: pointer;
}

.ve-card-title {
  font-size: 14px;
}

.ve-list {
  list-style: none;
}

.ve-filter-block {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.filter-open::before {
  font-weight: bold;
  content: "Filter";
  margin-right: 5px;
}

.filter-closed::before {
  font-weight: bold;
  content: "Filter";
  margin-right: 5px;
}
</style>