import * as THREE from 'three';
import { nextTick } from 'vue'

import theApp from '@/frame/Application';

import Action from '@/frame/Action';
import { BreakEvent } from '@/frame/Event.js';
import CommandLineScanner from '@/frame/CommandLineScanner';
import FltPointDef from '@/visual-events/actions/FltPointDef';
import FltSelectGrafic from '@/visual-events/actions/FltSelectGrafic';
import Geometry from '@/visual-events/model/Geometry';
import OpUtils from '@/visual-events/model/OpUtils';
import SelectGrafic from '@/visual-events/actions/SelectGrafic';
import Settings from '@/visual-events/data/Settings';
import Logger from '@/frame/Logger';
import OpText from '../model/OpText';

const logger = new Logger('ActEditStyle');

const State = Object.freeze({
    EDIT: 0,
  });
  
export default class ActEditStyle extends Action {
    constructor(args) {
      super();

      this.view2D = theApp.findViewByName('2D Ansicht');
      this.root2D = this.view2D.getRoot().children[0];

      this.useStroke = Settings.get('style.useStroke', true);
      this.stroke = Settings.get('style.stroke', '#000000');
      this.strokeWidth = Settings.get('style.strokeWidth');
      this.useFill = Settings.get('style.useFill', true);
      this.fill = Settings.get('style.fill', '#222222');

      this.objects = [];
      if (args.length>1)
        this.objects = args[1];

      this.state = State.EDIT;
    }

    actionStart () {
        logger.log(`actionStart`);

        this.addFltSelectGrafic();
        this.evaluateSelection();
        this.connectToGUI();
        return true;
    }

    actionDestroy () {
        logger.log(`actionDestroy`);

        this.resetCursor();
        this.disconnectFromGUI();
    }

    actionCommand(event) {
        logger.log(`actionCommand ${event.commandLine}`);

        const scanner = new CommandLineScanner(event.commandLine);
        const cmd = scanner.getCommand();

        switch (this.state) {
            case State.EDIT: {
                switch (cmd) {
                    case '.select.delete': {
                        this.deleteSelection();
                        return new BreakEvent();
                    }
                    case '.select.copy': {
                        this.copySelection();
                        return null;
                    }
                    case '.select.applyTransform': {
                        const tdiff = event.args[0];
                        for (const op of this.objects)
                            op.applyTransform(tdiff);
                        return null;
                    }
                }
            }
        }

        return event;
    }

    actionValue (event) {
        logger.log(`actionValue`);

        let done = false;

        if (event.attribute === 'useStroke') {
            this.useStroke = event.value;
            this.adaptSelectedObjects({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'stroke') {
            this.stroke = event.value;
            this.adaptSelectedObjects({ stroke: (this.useStroke ? this.stroke : 'none') });
            done = true;
        }

        if (event.attribute === 'strokeWidth') {
            this.strokeWidth = event.value;
            this.adaptSelectedObjects({ strokeWidth: this.strokeWidth });
            done = true;
        }

        if (event.attribute === 'useFill') {
            this.useFill = event.value;
            this.adaptSelectedObjects({ fill: (this.useFill ? this.fill : 'none') });
            done = true;
        }
        
        if (event.attribute === 'fill') {
            this.fill = event.value;
            this.adaptSelectedObjects({ fill: (this.useFill ? this.fill : 'none') });
            done = true;
        }

        return done ? null : event;
    }

    adaptSelectedObjects (optsStyle) {
        if (optsStyle) {
            this.objects.forEach(op => { 
                if (op instanceof OpText) {
                    optsStyle = { fill: this.fill, stroke: "none" };
                } 
                op.setStyle(optsStyle)
            });
            theApp.model.changed2d = true; //op;
        }
    }

    evaluateSelection () {

        //TODO: wenn unterschiedliche Styles, dann was???
        this.objects.forEach(op => {
            const style = op.style;
            this.useStroke = style.stroke !== 'none';
            this.stroke = style.stroke;
            this.strokeWidth = style.strokeWidth;
            this.useFill =  style.fill !== 'none';
            this.fill = style.fill;
        });

        const panelStyle = theApp.findDialogByName('PanelStyle');
        panelStyle?.update(this);
    }

    deleteSelection () {
        this.objects.forEach(op => (
            this.root2D.remove(op)
        ));
        theApp.model.changed2d = true;

        // State change to State.FIRST_POINT
        this.objects = [];
        this.disconnectFromGUI();
        this.state = State.EDIT;
        this.addFilter(new FltPointDef());
        this.connectToGUI();
    }

    copySelection () {
        const dist = Settings.get('selection.grafic.iconDist', 300);
        this.objects = OpUtils.copySelection (this.objects, this.root2D, dist);

        this.disconnectFromGUI();
        this.state = State.EDIT;
        this.getFilter().shift(dist);

        theApp.model.changed2d = true;
    }

    addFltSelectGrafic () {
        if (this.objects.length === 1) {
            const box = Geometry.computeBox(this.objects[0]);
            const transform = this.objects[0].transform;
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon().useBoxPoints());
        } else if (this.objects.length > 1) {
            const box = Geometry.computeBoxUnion(this.objects);
            const transform = new THREE.Matrix4(); // identity -> axis parallel
            this.addFilter(new FltSelectGrafic(box, transform).useDeleteIcon().useCopyIcon().useRotateIcon());
        }
    }

    resetCursor () {
        theApp.findDialogByName('main')?.setCursor(undefined);
        theApp.findDialogByName('2D Ansicht')?.setCursor(undefined);
        theApp.findDialogByName('3D Ansicht')?.setCursor(undefined);
    }

    connectToGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton('Select');
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel('PanelDraw');
        nextTick(() => {
            // access updated DOM
            const panelDraw = theApp.findDialogByName('PanelDraw');
            if (panelDraw) {
                panelDraw.showIcons = false;
                panelDraw.setTab('');
            }
        });
    }

    disconnectFromGUI () {
        const sideNav = theApp.findDialogByName('SideNav');
        sideNav.setActiveButton(undefined);
        const sidePane = theApp.findDialogByName('SidePane');
        sidePane.setCurrentPanel(undefined);
    }
}    
